import React from 'react';
import {Table, Space, Pagination, Row, Col, Form, Input, Button, DatePicker, Modal, message, Radio} from "antd";
import {withRouter} from "react-router-dom";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";
import NoData from "../../components/NoData";
import locale from "antd/es/date-picker/locale/zh_CN";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {rules} from "../../lib/help/rules";

const {pact, review_desc} = rules;
const {RangePicker} = DatePicker;
const {TextArea} = Input;

class ProjectFinancingList extends React.Component {
    formRef = React.createRef();
    reviewFormRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleRole: false,
            data: [],
            currentPage: 1,
            columns: [
                {
                    title: '产品编号',
                    dataIndex: 'ID',
                },
                {
                    title: '产品名称',
                    dataIndex: 'Name',
                },
                {
                    title: '类型',
                    dataIndex: 'ProductTypeStr',
                },
                {
                    title: '发布机构',
                    dataIndex: 'FinName',
                },
                {
                    title: '提交时间',
                    dataIndex: 'CreatedAt',
                    render: (CreatedAt) => {
                        return (localTime(CreatedAt))
                    }
                },
                {
                    title: '操作',
                    render: (text, record) => (
                        <Space size="middle" className={'operate-btn'}>
                            <p onClick={this.goDetails.bind(this, record)}>查看详情</p>
                            {
                                Number(this.props.activeKey) === 0 &&
                                <p onClick={this.editProject.bind(this, record, 'review')}>审核</p>
                            }
                            {
                                Number(this.props.activeKey) === 1 &&
                                <p onClick={this.editProject.bind(this, record, 'OffShelf')}>下架</p>
                            }
                            {
                                Number(this.props.activeKey) === 5 &&
                                <p onClick={this.editProject.bind(this, record, 'OffShelf')}>恢复</p>
                            }
                        </Space>
                    ),
                },
            ],
            listType: ['REVIEW-PASS', 'PUBLISHED', 'PUBLISH-REJECTED', 'REVIEW-REJECTED', 'PENDING-REVIEW', 'SHELVED'],
            searchInfo: {
                name: '',
                fin_name: '',
                start_date: '',
                end_date: '',
                status: '',
            },
            editRowsId: '',
            isModalVisible: false,
            reviewVisible: false,
            projectInfo: {},
            radioValue: 'PUBLISHED'
        }
    }

    goDetails = (record) => {
        this.props.history.push({
            pathname: '/product/company-list/' + record.ID,
            state: '产品详情'
        });
    };
    getList = () => {
        let params = this.state.searchInfo;
        params.status = this.state.listType[this.props.activeKey];
        params.page = this.state.currentPage;

        http.get(`/product/service-list`, params).then(res => {
            if (res) {
                if(this.props.type === 'index') {
                    this.props.setNullStatus && this.props.setNullStatus(res.data.total, 'companyTotal')
                    this.setState({
                        data: res.data.items ? res.data.items.slice(0, 3) : [],
                        total: res.data.total,
                        page: res.data.total_page,
                        currentPage: res.data.current_page
                    })
                }else {
                    this.setState({
                        data: res.data.items ? res.data.items : [],
                        total: res.data.total,
                        page: res.data.total_page,
                        currentPage: res.data.current_page
                    })
                }
            }
        })
    };

    editProject(rows, type) {
        if (type === 'OffShelf') {
            this.setState({
                editRowsId: rows.ID,
                isModalVisible: true,
            })
        } else {
            this.setState({
                projectInfo: rows,
                reviewVisible: true,
            })
        }
    }

    componentDidMount() {
        this.getList()
    }

    componentDidUpdate(prevProps, prevState) {
        const key = this.props.activeKey;
        let params = {
            page: this.state.currentPage
        };
        if (this.props.activeKey === '1' && (prevProps.activeKey !== this.props.activeKey)) {
            this.getList(key, params);
            this.setState({
                currentPage: 1
            })
        }
    }

    onFinish(value) {
        if (value.range_picker) {
            value.start_date = value.range_picker[0].format('YYYY-MM-DD')+" 00:00:00";
            value.end_date = value.range_picker[1].format('YYYY-MM-DD')+" 23:59:59";
            delete value.range_picker;
        }
        this.setState({
            currentPage: 1,
            searchInfo: {
                ...value
            }
        }, () => {
            this.getList();
        })
    }

    onChangePage = page => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getList()
        });
    };
    resetSearch = () => {
        this.formRef.current.resetFields();
        this.formRef.current.submit();
    }

    setTableKey() {
        let setKey = this.state.columns;
        if (Number(this.props.activeKey) === 1) {
            let finTime = {
                title: '发布时间',
                dataIndex: 'AdminAuditedAt',
                render: (AdminAuditedAt) => {
                    return (AdminAuditedAt ? localTime(AdminAuditedAt) : '--')
                }
            }
            setKey.splice(5, 0, finTime);
        }
        return setKey
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            reviewVisible: false
        })
    }
    editProjectConfirm = () => {
        let params = {
            product_id: this.state.editRowsId,
            product_type: 'SERVICE',
        }
        http.post(`/product/shelve`, params).then(res => {
            if (res) {
                message.success('操作成功');
                this.handleCancel();
                this.getList()
            }
        })
    }
    reviewProject = () => {
        this.reviewFormRef.current.submit();
    }
    onReviewFinish = (values) => {
        values.product_id = this.state.projectInfo.ID;
        values.product_type = 'SERVICE';
        http.post(`/product/audit`, values).then(res => {
            if (res) {
                message.success('操作成功');
                this.handleCancel();
                this.getList();
                this.reviewFormRef.current.resetFields()
            }
        })
    }
    RadioChange = (evt) => {
        this.setState({
            radioValue:evt.target.value
        })
    }
    render() {
        const {data, total, isModalVisible, reviewVisible, projectInfo, radioValue} = this.state;
        return (
            <div className="user-manage">
                <Form
                    name="basic"
                    className="form-content search-form-content"
                    ref={this.formRef}
                    onFinish={(values) => this.onFinish(values)}
                    onFinishFailed={() => this.onFinishFailed()}
                >
                    <Row gutter={24}>
                        <Col xl={6} lg={12}>
                            <Form.Item
                                label="产品名称"
                                name="name"
                                className={'search-item'}
                            >
                                <Input placeholder={'可按关键字模糊搜索'}/>
                            </Form.Item>
                        </Col>
                        <Col xl={6} lg={12}>
                            <Form.Item
                                label="发布机构"
                                name="fin_name"
                                className={'search-item'}
                            >
                                <Input placeholder={'可按关键字模糊搜索'}/>
                            </Form.Item>
                        </Col>
                        <Col xl={8} lg={12}>
                            <Form.Item
                                name="range_picker"
                                label="时间"
                                className={'not-extra date-time search-item'}
                            >
                                <RangePicker locale={locale} format="YYYY-MM-DD"/>
                            </Form.Item>
                        </Col>
                        <Col xl={4} lg={12} className="edit-btn-box">
                            <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    搜索
                                </Button>
                            </Form.Item>
                            <Form.Item className="reset-btn btn-submit">
                                <Button className={'edit-btn'} onClick={() => this.resetSearch()}>
                                    重置
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="user-content">
                    {total > 0 ? (<div className={'table-data'}>
                        <Table rowKey={'ID'} columns={this.setTableKey()} dataSource={data} pagination={false}/>
                        <div className={'total-data'}>
                            <Pagination
                                className={'page-style'}
                                onChange={this.onChangePage.bind(this)}
                                defaultCurrent={1}
                                total={total}
                                pageSize={10}
                                current={this.state.currentPage}
                                showSizeChanger={false}
                                showTotal={total => `共${total}条`}
                            />
                        </div>
                    </div>) : <div className="news-list-content">
                        <NoData/>
                    </div>}
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.editProjectConfirm}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring"/>
                        确定{Number(this.props.activeKey) === 1 ? '下架' : '恢复'}该产品吗?</p>
                    <p className="account-modal-desc">
                        {Number(this.props.activeKey) === 1 ? '下架后的产品将不再显示在前台网站中' : '恢复后的产品将重新显示在前台网站中'}
                    </p>
                </Modal>

                <Modal
                    visible={reviewVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.reviewProject}
                    className="set-padding modal-review-content"
                    onCancel={this.handleCancel}>

                    <p className='account-modal-title logout-title'>
                        审核
                    </p>
                    <div className="">
                        <div className="project-info">
                            <div className="info-item">
                                <p className="title">产品名称:</p>
                                <p className="desc">{projectInfo.Name}</p>
                            </div>
                            <div className="info-item">
                                <p className="title">发布机构:</p>
                                <p className="desc">{projectInfo.FinName}</p>
                            </div>
                        </div>
                        <div className="form-content add-from-content">
                            <div className="left-from">
                                <Form
                                    name="basic"
                                    ref={this.reviewFormRef}
                                    initialValues={{'audit_status': radioValue}}
                                    onFinish={(values) => this.onReviewFinish(values)}
                                >
                                    <Form.Item
                                        label=""
                                        name="audit_status"
                                        rules={pact}
                                        className={'radio-item'}
                                    >
                                        <Radio.Group onChange={this.RadioChange}>
                                            <Radio value={'PUBLISHED'}>通过</Radio>
                                            <Radio value={'PUBLISH-REJECTED'}>不通过</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {
                                        radioValue === 'PUBLISH-REJECTED' &&
                                        <Form.Item
                                            label=""
                                            name="reject_reason"
                                            rules={review_desc}
                                            className="not-extra input-remark"
                                        >
                                            <TextArea size="middle"  placeholder={'请输入驳回描述，不超过200字符'}
                                                      onPressEnter={(e) => this.preventBubble(e)}/>
                                        </Form.Item>
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ProjectFinancingList)
