import React from 'react';
import {Form, Input, Button, Row, Col, Modal, Pagination, Table, message, Space} from 'antd';
import http from "../../lib/service/http";
import NoData from "../../components/NoData";
import {localTime} from "../../lib/help/helper";
import './index.scss'

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}


export default class FinancingRequirement extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            options: [
                {value:'LIQUIDITY',name: '流动资金'},
                {value:'FIXED',name: '固定资产'},
                {value:'FINANCING',name: '项目融资'},
                {value:'OTHER',name: '其他'}
            ],
            columns: [
                {
                    title: '企业名称',
                    dataIndex: 'EnterpriseName',
                    key: 'EnterpriseName',
                },
                {
                    title: '期望融资金额(万元)',
                    dataIndex: 'Amount',
                    key: 'Amount',
                },
                {
                    title: '期望年化利率',
                    render: (item) => {
                        return item.RateFloor+'%-'+item.RateCeil+'%'
                    }
                },
                {
                    title: '融资期限(个月)',
                    dataIndex: 'Cycle',
                    key: 'Cycle'
                },
                {
                    title: '担保方式',
                    dataIndex: 'SecuredMode',
                    key: 'SecuredMode'
                },
                {
                    title: '联系方式',
                    dataIndex: 'Contact',
                    key: 'Contact'
                },
                {
                    title: '融资用途',
                    dataIndex: 'FinancingPurpose',
                    key: 'FinancingPurpose',
                    render: (FinancingPurpose) => {
                        return this.SelectOptions(FinancingPurpose)
                    }
                },
                {
                    title: '发布时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                },
                {
                    title: '操作',
                    render: (text, record) => (
                        <Space size="middle" className={'operate-btn'}>
                            <p onClick={()=>this.delConfirm(record)}>删除</p>
                        </Space>
                    ),
                },
            ],
            total: 0,
            currentPage: 1,
            visible: false,
            isModalVisible: false,
            modalType: 'enable',
            userId: '',
            searchInfo: {
                keywords: '',
                fin_name: '',
            }
        }
    }

    componentDidMount() {
        this.getListData()
    }

    SelectOptions=(key)=> {
        return(this.state.options.filter(function(item){
            return item.value===key;
        })[0].name)
    };
    componentWillUnmount() {
    }
    delConfirm=(record)=> {
        const that = this;
        Modal.confirm({
            className: 'info-disable',
            width: '416px',
            title: (
                <div className={'disable-title'}>确定删除该数据吗?</div>
            ),
            content:(
                <div className={'disable-text'}>删除后将无法撤回</div>
            ),
            okText: '确定',
            cancelText: '取消',
            onCancel() {},
            onOk() {
                http.post('/financing/deleted-financing', {id:record.ID}).then(res => {
                    if (Object.keys(res).length===0) {
                        message.success({
                            content: '删除成功',
                        });
                        that.getListData();
                    }
                }, () => {
                })
            },
        });
    };
    getListData = () => {
        let params = this.state.searchInfo;
        params.page = this.state.currentPage;
        http.get('/financing/financing-list', params).then(res => {
            this.setState({
                dataList: res.data.items ? res.data.items : [],
                total: res.data.total
            });
        })
    }
    onChangePage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getListData()
        });
    }

    onFinish(value) {
        this.setState({
            currentPage: 1,
            searchInfo: {
                ...value
            }
        }, ()=> {
            this.getListData();
        })
    }

    onFinishFailed(value) {
    }

    addUser = () => {
        this.setState({
            visible: true,
        });
    };
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    confirmHandleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    resetSearch = () => {
        this.formRef.current.resetFields();
        this.formRef.current.submit();
    }

    render() {
        const {columns, dataList, total, currentPage, visible } = this.state;
        return (
            <div className="admin-user-container main-container">
                <div className="admin-user-content">
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        className="form-content search-form-content"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Row gutter={24}>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="企业名称"
                                    name="enterprise_name"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={7} lg={12} className="edit-btn-box">
                                <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                                    <Button className={'submit-btn'} type="primary" htmlType="submit">
                                        搜索
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>


                    </Form>
                    {
                        dataList.length > 0 ?
                            <div className="list-box">
                                <Table rowKey={'ID'} columns={columns} dataSource={dataList} pagination={false}/>
                                <Pagination
                                    className={'page-style'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={10}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    showTotal={total => `共${total}条`}
                                />
                            </div> :
                            <div className="list-box">
                                <NoData/>
                            </div>
                    }

                </div>
            </div>
        )
    }
}
