import React from 'react';
import './index.scss'
import http from "../../lib/service/http";
import {localTime, setNull, getImgUrl} from "../../lib/help/helper";
import {Pagination, Table} from "antd";
import NoData from "../../components/NoData";
import jigouLogo from "../../resource/images/public/jigou_logo.svg"

export default class AuthorIndex extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            info: {},
            user: {},
            subAccountList: [],
            columns: [
                {
                    title: 'UID',
                    dataIndex: 'ID',
                    key: 'ID',
                },
                {
                    title: '账号',
                    dataIndex: 'UserName',
                    key: 'UserName',
                    render: (Name)=> {
                        return Name ? Name : '- -'
                    }
                },
                {
                    title: '手机号',
                    dataIndex: 'Telephone',
                    key: 'Telephone',
                },
                {
                    title: '邮箱',
                    dataIndex: 'Email',
                    key: 'Email',
                    render: (Email)=> {
                        return Email ? Email : '- -'
                    }
                },
                {
                    title: '添加时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                }
            ],
            page: 1,
            total: 0,
            currentPage: 1,
        }
    }

    getUserInfo = () => {
        http.get(`/front-user/detail/${this.props.match.params.id}`).then(res => {
            if (res) {
                this.setState({
                    info: res.info ? res.info : {},
                    user: res.user ? res.user : {},
                })
            }
        })
    }
    subAccount(){
        let params = {
            page: this.state.currentPage
        }
        http.get(`/front-user/sub-account-list/${this.props.match.params.id}`, params).then(res => {
            if (res) {
                this.setState({
                    subAccountList: res.data.items ? res.data.items : [],
                    total: res.data.total,
                    page: res.data.total_page,
                    currentPage: res.data.current_page
                })
            }
        })
    }
    onChangePage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.subAccount()
        });
    }

    componentDidMount() {
        this.getUserInfo();
        this.subAccount();
    }

    componentWillUnmount() {
    }

    render() {
        const {user, info, subAccountList, columns, total, currentPage} = this.state;
        return (
            <div className="account-info-container main-container user-financial-info">
                <div className="info-title-box">
                    <p className="title">账号信息</p>
                </div>
                <div className="info-content">
                    <div className="item-type-box">
                        <div className="item-box">
                            <p className="label-text">手机号</p>
                            <p className="desc">{user.Telephone}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">注册时间</p>
                            <p className="desc">{localTime(user.CreatedAt)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">机构账号</p>
                            <p className="desc">{user.UserName}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">邮箱</p>
                            <p className="desc">{setNull(user.Email)}</p>
                        </div>
                    </div>
                </div>
                <div className="info-title-box">
                    <p className="title">机构信息</p>
                </div>
                <div className="info-content">
                    <div className="item-type-box">
                        <div className="item-box">
                            <p className="label-text">机构名称</p>
                            <p className="desc">{setNull(info.Name)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">机构网址</p>
                            <p className="desc">{setNull(info.Website)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">机构地址</p>
                            <p className="desc">{setNull(info.Address)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">机构Logo</p>
                            {
                                info.LogoFid ?
                                    <div className="img-box">
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LogoFid)} alt=""/>
                                        </div>
                                    </div> :
                                    <p className="desc"><img src={jigouLogo} alt=""/></p>
                            }
                        </div>
                        <div className="item-box">
                            <p className="label-text">联系人</p>
                            <p className="desc">{setNull(info.ContactName)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">联系人职位</p>
                            <p className="desc">{setNull(info.ContactPosition)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">联系人电话</p>
                            <p className="desc">{setNull(info.ContactTelephone)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">机构主营业务、资质</p>
                            <p className="desc">{setNull(info.BusinessScope)}</p>
                        </div>
                    </div>
                    <div className="info-title-box">
                        <p className="title">子账号信息</p>
                    </div>
                    {
                        subAccountList.length > 0 ?
                            <div className="list-box">
                                <Table rowKey={'ID'} columns={columns} dataSource={subAccountList} pagination={false}/>
                                <Pagination
                                    className={'page-style'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={8}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    showTotal={total => `共${total}条`}
                                />
                            </div> :
                            <div className="list-box">
                                <NoData/>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
