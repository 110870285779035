import React from 'react';
import {Timeline} from "antd";
import './index.scss'
import Avatar  from "../../resource/images/public/user-avatar.svg"
import {localTime} from "../../lib/help/helper";
export default class RecordIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordData: {}
    }
  }
  componentWillMount() {
    const data = this.props.recordData;
  this.setState({
    recordData: data
  });
}

  render() {
    return (
      <div className="admin-user-container main-container">
        <div className={'operate-record'}>操作记录</div>
        <div className={'time-line'}>
          <Timeline>
            {this.props.recordData.OrderTimeLineEnterpriseApply&&
                <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
                  <p className={'review-triangle'}/><p className={'review-triangle-text'}>企业申请记录</p>
                </div></Timeline.Item>
           }
            {this.props.recordData.OrderTimeLineEnterpriseApply&&this.props.recordData.OrderTimeLineEnterpriseApply.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  <div className={'review-info'}>
                    <div><img src={Avatar} alt="" className={'review-avatar'}/>申请人：{item.Operator}</div>
                    {/*<div className={'review-result'}>审核结果：下架</div>*/}
                    {/*<div className={'review-result'}>审核结果：下架</div>*/}
                  </div>
                  <div className={'review-time'}>
                    操作时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            {this.props.recordData.OrderTimeLineDistribute&&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>机构分配记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.OrderTimeLineDistribute&&this.props.recordData.OrderTimeLineDistribute.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  <div className={'review-info'}>
                    <img src={Avatar} alt="" className={'review-avatar'}/>
                    <div>操作人：{item.Operator}</div>
                    <div>分配至：{item.Remark}</div>
                  </div>
                  <div className={'review-time'}>
                    操作时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            {this.props.recordData.OrderTimeLineMatch&&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>机构撮合记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.OrderTimeLineMatch&&this.props.recordData.OrderTimeLineMatch.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  <div className={'review-info'}>
                    <img src={Avatar} alt="" className={'review-avatar'}/>
                    <div>操作人：{item.Operator}</div>
                    <div>撮合结果：{item.OperationNext}</div>
                  </div>
                  <div className={'review-time'}>
                    操作时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            {this.props.recordData.OrderTimeLineOthers&&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>其他记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.OrderTimeLineOthers&&this.props.recordData.OrderTimeLineOthers.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  {item.Operation==='关闭'?
                    <div className={'review-info'}>
                      <img src={Avatar} alt="" className={'review-avatar'}/>
                      <div>操作人：{item.Operator}</div>
                      <div>操作结果：{item.OperationNext}</div>
                    </div>:  <div className={'review-info'}>
                      <div>{item.Operation}</div>
                    </div>}
                  <div className={'review-time'}>
                    操作时间：{item.Operation==='关闭'?localTime(item.CreatedAt):localTime(item.UpdatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            <Timeline.Item  color="#C38B3A"/>
          </Timeline>
        </div>
      </div>
    )
  }
}
