import {
    regexps_password,
    regexps_account,
    regexps_phone,
    regexps_img_code,
    regexps_phone_code,
    regexps_email,
    regexps_invitation_code,
    regexps_id_card,
    regexps_capitalized_amount,
    regexps_credit_code,
    regexps_product_name,
    regexps_product_num,
    regexps_product_other,
    regexps_product_rate,
    regexps_product_date,
    regexps_product_email_phone,
    regexps_review_desc,
    regexps_add_user_remark_desc,
    regexps_news_title,
    regexps_news_source
} from './validates'

import {fileMax, fileName, fileType} from "./public";

export const rules = {
    account: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_account,
            message: '输入项错误，需输入6-20位字符,含数字,字母中的一种及以上',
        }
    ],
    account_null: [
        {
            required: false,
            message: '该项不可为空',
        },
        {
            pattern: regexps_account,
            message: '输入项错误，需输入6-20位字符,含数字,字母中的一种及以上',
        }
    ],
    roleName: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_product_name,
            message: '请输入20位字符',
        }
    ],
    password: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_password,
            message: '8-16位字符，含数字、大小写字母、特殊字符中的三种及以上',
        },
    ],
    phone: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_phone,
            message: '手机号码格式错误',
        },
    ],
    img_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_img_code,
            message: '格式错误',
        },
    ],
    phone_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_phone_code,
            message: '格式错误',
        },
    ],
    email: [
        {
            required: false,
        },
        {
            pattern: regexps_email,
            message: '格式错误',
        },
    ],
    emailUser: [
        {
            required: true,
            message: '请输入邮箱',
        },
        {
            pattern: regexps_email,
            message: '格式错误',
        },
    ],
    pact: [
        {
            required: true,
            message: '请勾选',
        },
        // {
        //     validator: (_, value) =>{
        //         value ? Promise.resolve() : Promise.reject('请勾选')
        //     }
        // },
    ],
    pactSecured: [
        {
            required: true,
            message: '请勾选',
        }
    ],
    invitation_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_invitation_code,
            message: '格式错误',
        },
    ],
    id_card: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_id_card,
            message: '格式错误',
        },
    ],
    upload_product_file: [
        {
            required: true,
            message: '请上传',
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {size: process.env.REACT_APP_FILE_SIZE})
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback);
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback);
            }
        },
    ],
    upload_file: [
        {
            required: true,
            message: '请上传',
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {size: process.env.REACT_APP_FILE_SIZE});
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback);
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback);
            }
        }
    ],
    upload_file_not_required: [
        {
            required: false,
        },
        {
            validator: (_, value, callback) => {
                fileMax(_, value, callback, {require: false, size: process.env.REACT_APP_FILE_SIZE})
            }
        },
        {
            validator: (_, value, callback) => {
                fileName(_, value, callback, {require: false});
            }
        },
        {
            validator: (_, value, callback) => {
                fileType(_, value, callback,{require: false});
            }
        }
    ],
    not_null: [
        {
            required: true,
            message: '请输入',
        }
    ],
    time_zone: [
        {
            required: true,
            message: '请选择'
        }
    ],
    is_number: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_capitalized_amount,
            message: '格式错误',
        }
    ],
    is_select: [
        {
            required: true,
            message: '请选择'
        },
    ],
    credit_code: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_credit_code,
            message: '格式错误',
        }
    ],
    product_name: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_name,
            message: '输入内容过长，最多输入20字符',
        }
    ],
    banner_name: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_add_user_remark_desc,
            message: '输入内容过长，最多输入100字符'
        }
    ],
    product_num: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_num,
            message: '输入非负金额，精度最大四位小数',
        }
    ],
    product_other: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_other,
            message: '输入内容过长，最多输入1000字符',
        }
    ],
    product_rate: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_rate,
            message: '输入非负利率，精度最大俩位小数',
        }
    ],
    product_date: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_date,
            message: '请输入正确的期限',
        }
    ],
    product_contact: [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_product_email_phone,
            message: '请输入手机号或邮箱地址',
        }
    ],
    review_desc : [
        {
            required: true,
            message: '请输入'
        },
        {
            pattern: regexps_review_desc,
            message: '不超过200字'
        }
    ],
    add_user_remark: [
        {
            require: false,
        },
        {
            pattern: regexps_add_user_remark_desc,
            message: '不超过100字'
        }
    ],
    add_news_title: [
        {
            required: true,
            message: '该项不可为空'
        },
        {
            pattern: regexps_news_title,
            message: '不超过100字'
        }
    ],
    add_news_source: [
        {
            required: true,
            message: '该项不可为空'
        },
        {
            pattern: regexps_news_source,
            message: '不超过50字'
        }
    ]
}
