import React from 'react';
import {Table, Space, Pagination, Modal, message} from "antd";
import {withRouter, Link} from "react-router-dom";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";
import NoData from "../../components/NoData";
import {ExclamationCircleOutlined} from "@ant-design/icons";


class ContentNewsList extends React.Component {
    formRef = React.createRef();
    reviewFormRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            visibleRole: false,
            data: [],
            currentPage: 1,
            columns: [
                {
                    title: '标题',
                    dataIndex: 'Title',
                },
                {
                    title: '来源',
                    dataIndex: 'Source',
                },
                {
                    title: '附件',
                    dataIndex: 'AttachFile',
                    render: (text, record) => (
                        <p>{text ? text.split(',').length : 0}</p>
                    )
                },
                {
                    title: '操作',
                    render: (text, record) => (
                        <Space size="middle" className={'operate-btn'}>
                            <p onClick={()=>this.goDetails(record)}>编辑</p>
                            <p onClick={()=>this.showConfirm(record.ID, 'delete')}>删除</p>
                            {
                                Number(this.props.activeKey) === 1 &&
                                <p onClick={()=>this.showConfirm(record.ID, 'publish')}>发布</p>
                            }
                        </Space>
                    ),
                },
            ],
            listType: ['PUBLISHED', 'DRAFT'],
            editRowsId: '',
            isModalVisible: false,
            projectInfo: {},
            radioValue: 'PUBLISHED',
            editID: ''
        }
    }

    goDetails = (record) => {
        sessionStorage.setItem("editNews", JSON.stringify(record));
        this.props.history.push({
            pathname: '/content/news/edit/'+record.ID,
        });
    };
    getList = () => {
        let params = {};
        params.page = this.state.currentPage;
        params.status = this.state.listType[this.props.activeKey]
        http.get(`/news/list`, params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items ? res.data.items : [],
                    total: res.data.total,
                    page: res.data.total_page,
                    currentPage: res.data.current_page
                })
            }
        })
    };


    componentDidMount() {
        this.getList()
    }

    componentDidUpdate(prevProps, prevState) {
        const key = this.props.activeKey;
        let params = {
            page: this.state.currentPage
        };
        if (this.props.activeKey === '1' && (prevProps.activeKey !== this.props.activeKey)) {
            this.getList(key, params);
            this.setState({
                currentPage: 1
            })
        }
    }


    onChangePage = page => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getList()
        });
    };
    showConfirm = (ID, type) => {
        this.setState({
            type: type,
            isModalVisible: true,
            editID: ID,
        })
    }

    setTableKey() {
        let setKey = this.state.columns;
        if (Number(this.props.activeKey) === 0) {
            let finTime = {
                title: '发布时间',
                dataIndex: 'PublishedAt',
                render: (PublishedAt) => {
                    return (PublishedAt ? localTime(PublishedAt) : '--')
                }
            }
            setKey.splice(2, 0, finTime);
        }
        return setKey
    }

    handleCancel = () => {
        this.setState({
            isModalVisible: false,
        })
    }
    editProjectConfirm = () => {
        let params = {
            id: this.state.editID,
        }
        let url = '/news/delete';
        if(this.state.type === 'publish') {
            url = '/news/publish'
        }
        http.post(url, params).then(res => {
            if (res) {
                message.success('操作成功');
                this.handleCancel();
                this.getList()
            }
        })
    }
    reviewProject = () => {
        this.reviewFormRef.current.submit();
    }

    render() {
        const {data, total, isModalVisible} = this.state;
        return (
            <div className="user-manage">
                <div className="search-form-content">
                    <Link to={'/content/news/add'} className="add-news">
                        添加政策
                    </Link>
                </div>
                <div className="user-content">
                    {total > 0 ? (<div className={'table-data'}>
                        <Table rowKey={'ID'} columns={this.setTableKey()} dataSource={data} pagination={false}/>
                        <div className={'total-data'}>
                            <Pagination
                                className={'page-style'}
                                onChange={this.onChangePage.bind(this)}
                                defaultCurrent={1}
                                total={total}
                                pageSize={10}
                                showSizeChanger={false}
                                current={this.state.currentPage}
                                showTotal={total => `共${total}条`}
                            />
                        </div>
                    </div>) : <div className="news-list-content">
                        <NoData/>
                    </div>}
                </div>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.editProjectConfirm}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring"/>
                        是否确定{this.state.type === 'publish' ? '发布' : '删除'}该条政策</p>
                    <p className="account-modal-desc">
                        {this.state.type === 'publish' ? '' : '删除后无法恢复'}
                    </p>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ContentNewsList)
