import React from 'react';
import { Upload } from 'antd';
import './index.scss'
import iconUpload from "../../resource/images/upload/file-upload-icon-add.svg";
import http from "../../lib/service/http";
import {getImgUrl, genUuid} from '../../lib/help/helper'
import {regexps_file_name} from "../../lib/help/validates";

const { Dragger } = Upload;



export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OSSData: {},
            successData: {},
            uploadStatus: '',
            fileList: [],
            fileSizeStatus: false,
            previewImage: '',
        }
        if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    async componentDidMount() {
        const { imgPath } = this.props;
        if(imgPath) {
            this.setState({
                fileList: [{url: imgPath}],
                uploadStatus: 'done'
            })
        }
        await this.init();
    }
    init = async () => {
        let OSSData = (await this.mockGetOSSData());
        OSSData.accessId = OSSData.accessid;
        delete OSSData.accessid;
        this.setState({
            OSSData: OSSData,
        });
    };
    mockGetOSSData = async () => {
        return await http.get('/upload/sign').then(res=>{
            return res
        })
    };

    onChange = ({ fileList }) => {
        const { onChange, fileKey } = this.props;
        this.setState({
            successData: fileList[fileList.length-1],
            uploadStatus: fileList[fileList.length-1].status,
            fileList: [fileList[fileList.length-1]]
        }, ()=> {
        })

        if (onChange && fileKey) {
            if(fileList[fileList.length-1].status === 'done') {
                onChange(fileKey, fileList[fileList.length-1]);
            }else {
                onChange(fileKey, fileList[fileList.length-1]);
            }
        }
    }
    onRemove = file => {
        const { value, onChange } = this.props;

        const files = value.filter(v => v.url !== file.url);

        if (onChange) {
            onChange(files);
        }
    };

    getExtraData = file => {
        const { OSSData } = this.state;

        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessId,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };
    beforeUpload = async (file) => {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
            file.width = img.width;
            file.height = img.height;
        };
        img.src = _URL.createObjectURL(file);
        const { OSSData } = this.state;
        const { onChange, fileKey } = this.props;
        this.handlePreview(file);
        const expire = OSSData.expire * 1000;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = genUuid() + suffix;
        file.url = OSSData.dir + '/' + filename;

        return new Promise(async (resolve, reject) => {
            if (expire < Date.now()) {
                await this.init();
            }
            if(file.size > process.env.REACT_APP_FILE_SIZE) {
                this.resetUpload();
                onChange && onChange(fileKey, file)
                reject(file);
                return;
            }
            if(!regexps_file_name.test(file.name.slice(0, file.name.indexOf('.')))) {
                this.resetUpload();
                onChange && onChange(fileKey, file)
                reject(file);
                return;
            }
            if(suffix.indexOf('png') === -1 && suffix.indexOf('jpg') === -1&& suffix.indexOf('jpeg') === -1) {
                this.resetUpload();
                onChange && onChange(fileKey, file)
                reject(file);
                return;
            }
            resolve(file);

        });
    };

    getFileType(imageName) {
        if (imageName && imageName.name) {
            let tmpName = imageName.name;
            return tmpName.substr(tmpName.indexOf('.') + 1,)
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file);
        }

        this.setState({
            previewImage: file.preview,
        });
    };

    resetUpload() {
        this.setState({
            uploadStatus: ''
        })
    }


    render() {
        const props = {
            name: 'file',
            accept: 'image/png, image/jpeg,image/bmp',
            fileList: this.state.fileList,
            action: this.state.OSSData.host,
            method: 'post',
            onChange: this.onChange,
            onRemove: this.onRemove,
            // transformFile: this.transformFile,
            data: this.getExtraData,
            beforeUpload: this.beforeUpload,
            multiple: false,
            progress: {
                strokeWidth: 2,
                strokeColor: '#EA4335',
                // showInfo: true
            },
            showUploadList: false,
            onPreview: this.handlePreview
        };
        const { defImg } = this.props;

        return (
          <div className="upload-container">
              <Dragger {...props} className={'upload-box'}>
                  {
                      this.state.uploadStatus === 'done' ?
                        <div className="upload-dialog">
                            <div className="file-type">
                                {
                                    this.state.fileList[0].status ?
                                      <img src={this.state.previewImage} alt="" className="file-image"/> :
                                      <img src={getImgUrl(this.state.fileList[0].url)} alt="" className="file-image"/>
                                }
                            </div>
                            <div className="change-dialog">
                                <img src={defImg ? defImg : iconUpload} className={'icon-change'} alt=""/>
                                <span className={'change-text'}>更换文件</span>
                            </div>
                        </div>
                        :
                        <div className="upload-dialog">
                            <img src={defImg ? defImg : iconUpload} alt="" className="icon-upload"/>
                            {/*<p className="upload-text">拖动上传或点击打开文件上传</p>*/}
                        </div>
                  }
              </Dragger>
              {/*{*/}
              {/*    this.state.uploadStatus === 'error' &&*/}
              {/*    <p className="error-text">文件上传失败请稍后重试</p>*/}
              {/*}*/}
              {/*{*/}
              {/*    this.state.fileSizeStatus &&*/}
              {/*    <p className="error-text error-size">请上传小于{*/}
              {/*        this.state.OSSData.max_upload_size && (this.state.OSSData.max_upload_size/1024).toFixed(2)*/}
              {/*    }kb的文件</p>*/}
              {/*}*/}
          </div>
        )
    }
}
