import React from 'react';
import {Tabs} from "antd";
import DebtList from "./DebtList";

const {TabPane} = Tabs;


export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '0'
        }
    }

    changeTab = (key) => {
        this.setState({
            activeKey: key
        })
    };

    render() {
        return (
            <div className="debt-finance main-container admin-user-container">
                <div className="debt-content admin-user-content">
                    <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
                        <TabPane tab={
                            <p className="tab-item tab-item-1">待平台审核</p>
                        } key="0">
                            {this.state.activeKey === '0' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">已发布</p>
                        } key="1">
                            {this.state.activeKey === '1' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">发布驳回</p>
                        } key="2">
                            {this.state.activeKey === '2' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">机构驳回</p>
                        } key="3">
                            {this.state.activeKey === '3' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">待机构审核</p>
                        } key="4">
                            {this.state.activeKey === '4' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">已下架</p>
                        } key="5">
                            {this.state.activeKey === '5' ? <DebtList activeKey={this.state.activeKey}/> : <div/>}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
