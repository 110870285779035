import React from 'react';
import {Form, Input, Button, Row, Col, Modal, Pagination, Table, message} from 'antd';
import http from "../../lib/service/http";
import NoData from "../../components/NoData";
import {localTime} from "../../lib/help/helper";


const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}


export default class InvitationCodeList extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            columns: [
                {
                    title: '邀请码',
                    dataIndex: 'Code',
                    key: 'Code',
                },
                {
                    title: '生成时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'Status',
                    key: 'Status',
                },
                {
                    title: '使用机构用户名',
                    dataIndex: 'UserName',
                    key: 'UserName',
                    render: (text) => {
                        return text ? text : '- -'
                    }
                },
                {
                    title: '使用机构名称',
                    key: 'FinName',
                    dataIndex: 'FinName',
                    render: (text) => {
                        return text ? text : '- -'
                    }
                },
                {
                    title: '使用时间',
                    dataIndex: 'UsedAt',
                    key: 'UsedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                },
            ],
            total: 0,
            currentPage: 1,
            visible: false,
            isModalVisible: false,
            modalType: 'enable',
            userId: '',
            searchInfo: {
                keywords: '',
                fin_name: '',
            }
        }
    }

    componentDidMount() {
        this.getListData()
    }

    componentWillUnmount() {
    }

    getListData = () => {
        let params = this.state.searchInfo;
        params.page = this.state.currentPage;
        http.get('/invitation-code/list', params).then(res => {
            this.setState({
                dataList: res.data.items ? res.data.items : [],
                total: res.data.total,
                page: res.data.total_page,
                currentPage: res.data.current_page
            });
        })
    }
    onChangePage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getListData()
        });
    }

    onFinish(value) {
        this.setState({
            currentPage: 1,
            searchInfo: {
                ...value
            }
        }, ()=> {
            this.getListData();
        })
    }

    onFinishFailed(value) {
    }

    addUser = () => {
        this.setState({
            visible: true,
        });
    };
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    confirmHandleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    resetSearch = () => {
        this.formRef.current.resetFields();
        this.formRef.current.submit();
    }
    getInviteCode = () => {
        http.post('/invitation-code/generate').then(() => {
            message.success('生成成功')
            this.setState({
                currentPage: 1,
            }, ()=> {
                this.getListData()
                this.handleCancel()
            })
        })
    }

    render() {
        const {columns, dataList, total, currentPage, visible } = this.state;
        return (
            <div className="admin-user-container main-container">
                <div className="admin-user-content">
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        className="form-content search-form-content"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Row gutter={24}>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="邀请码"
                                    name="keywords"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="机构名称"
                                    name="fin_name"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={7} lg={12} className="edit-btn-box">
                                <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                                    <Button className={'submit-btn'} type="primary" htmlType="submit">
                                        搜索
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} className="reset-btn btn-submit">
                                    <Button className={'edit-btn'} onClick={() => this.resetSearch()}>
                                        重置
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} className="add-btn-box btn-submit">
                                    <Button className={'edit-btn'} onClick={() => this.addUser()}>
                                        生成邀请码
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>


                    </Form>
                    {
                        dataList.length > 0 ?
                            <div className="list-box">
                                <Table rowKey={'ID'} columns={columns} dataSource={dataList} pagination={false}/>
                                <Pagination
                                    className={'page-style'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={10}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    showTotal={total => `共${total}条`}
                                />
                            </div> :
                            <div className="list-box">
                                <NoData/>
                            </div>
                    }

                </div>
                <Modal title={'生成邀请码'}
                       visible={visible}
                       onCancel={this.handleCancel}
                       onOk={this.getInviteCode}
                       okText={'确定'}
                       cancelText={'取消'}
                       width={640}
                       className={'set-padding'}
                       wrapClassName='modal-add-user'
                       destroyOnClose={true}
                >
                    邀请码仅能被使用一次，有效期自生成时间起24h内，过期自动失效
                </Modal>
            </div>
        )
    }
}
