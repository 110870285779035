import React from 'react';
import {Button, Form, Input} from 'antd';
import {Link} from 'react-router-dom';
import './index.scss'
import http from './../../lib/service/http'
import Cookie from 'js-cookie';
import {rules} from "../../lib/help/rules";
import imgLogin from '../../resource/images/public/img-login.svg'

const {account, password, img_code} = rules;


const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}

export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            imageCode: '',
            uuid: '',
        }
    }

    onFinish(values) {
        values.uuid = this.state.uuid;
        http.post('/user/login', values).then(res => {
            Cookie.set('token', res.token);
            res.username = values.username;
            sessionStorage.setItem('loginInfo', JSON.stringify(res));
            this.getUserInfo();
        }, () => {
            this.formRef.current.setFieldsValue({captcha: ''})
            this.getImgCode();
        })
    };

    getUserInfo() {
        http.get('/user/info').then(res => {
            sessionStorage.setItem('adminUserInfo', JSON.stringify(res));
            this.props.history.replace('/index')
        })
    };
    getImgCode = () => {
        http.get('/image-captcha/generate').then(res => {
            this.setState({
                imageCode: res.data.image,
                uuid: res.data.uuid
            })
        })
    }
    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        this.getImgCode()
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className="login-container">
                <div className="login-content">
                    <div className="login-left">
                        <p className="title">欢迎来到</p>
                        <p className="desc">天下大足管理后台操作系统</p>
                        <img src={imgLogin} alt="" className="img-login"/>
                    </div>
                    <div className="login-right">
                        <p className="login-title">
                            登录
                        </p>
                        <p className="left-desc">
                            发现更大的世界
                        </p>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}
                        >
                            <Form.Item
                                label=""
                                name="username"
                                rules={account}
                            >
                                <Input size="large" placeholder={'请输入用户名'}/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="password"
                                rules={password}
                            >
                                <Input.Password size="large" placeholder={'请输入密码'}/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="captcha"
                                rules={img_code}
                                className="number-code"
                            >
                                <Input
                                    suffix={<img src={'data:image/jpeg;base64,' + this.state.imageCode}
                                                 onClick={this.getImgCode} alt=""
                                                 className='img-code'/>}
                                    size="large" placeholder={'请输入图片识别码'}/>
                            </Form.Item>

                            <Form.Item {...tailLayout} className="submit-btn-box">
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="register-box">
                            <Link to={'/retrieve-password'} className={'text-link'}>忘记密码</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
