import React from 'react';
import {withRouter} from "react-router-dom";
import Upload from '../../components/Upload';
import {rules} from '../../lib/help/rules';
import Img from '../../resource/images/public/img-default.jpg'
import { Button, Form, Input, message, Select} from "antd";
import http from "../../lib/service/http";
const {Option} = Select;
const {banner_name,is_select,upload_file} = rules;
 class AddBanner extends React.Component {
   formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      orderData: []
    }
  }
   onFileChange = (fileKey, file) => {
     this.formRef.current.setFieldsValue({[fileKey]: file});
     this.formRef.current.validateFields([fileKey]);
   };
   onFinishFailed(errorInfo) {
   };
   onFinish=(values)=>{
     const that= this;
     values.image = values.image.url;
     values.rank= values.rank[0];
     http.post('/banner/create', values).then(res => {
       message.success({
         content: '新增成功',
       });
       that.props.history.push('/content/banner');
     }, () => {

     });
   };
   onChangeOrder= ()=> {
   };
   componentDidMount() {
     const number =Number(this.props.location.search.split('?')[1])+1;
     const arr =  [];
     for (let i = 1; i <= number; i++) {
       arr.push([i]);
     }
     this.setState({
       orderData: arr
     })
   }
   goBack() {
     this.props.history.push('/content/banner');
   }
   render() {
    return (
      <div className="admin-user-container  main-container home-banner banner-add">
        <div className={'back'}  onClick={this.goBack.bind(this)}>返回></div>
        <Form
          name="basic"
          ref={this.formRef}
          onFinish={(values) => this.onFinish(values)}
          onFinishFailed={() => this.onFinishFailed()}>
          <Form.Item
            label="banner名称"
            name="info"
            rules={banner_name}
            className={'item-height'}
            validateFirst={true}
          >
            <Input size="middle" placeholder={'请输入banner名称'} maxLength={100} />
          </Form.Item>
          <Form.Item
            label="banner序号"
            name="rank"
            rules={is_select}
            extra={<div className={'extra'}><p>序号代表轮播位置,1即为首图，2为第二张...若需顶替原有位置</p><p>则选择相应序号即可</p></div>}
            className={'item-height'}
            validateFirst={true}
          >
            <Select placeholder="请选择banner序号" className={'search-select'}  onChange={(e) =>this.onChangeOrder(e)}>
              {
                this.state.orderData.map((item) => {
                  return <Option value={item} key={item}>{item}</Option>
                })
              }

            </Select>
          </Form.Item>
          <Form.Item
            label="图片"
            name="image"
            className={'not-extra upload-item'}
            rules={upload_file}
            extra={<div className={'extra'}><p>建议尺寸:图片尺寸为1920*480(宽:高) 像素</p><p>建议格式:jpg,png...</p>
              <p>文件大小不超过2M</p><p>不符合条件的图片有可能导致图片变形,显示异常,请谨慎上传</p></div>}
            validateFirst={true}
            style={{width: "360px"}}
          >
            <Upload
              onChange={this.onFileChange.bind(this)}
              defImg={Img}
              fileKey={'image'}
            />
          </Form.Item>

          <Form.Item className="footer-btn">
            <Button className={'submit-btn'} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default withRouter(AddBanner)
