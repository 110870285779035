import React from 'react';
import {Form, Input, Button, DatePicker, Row, Col, Pagination, Table} from 'antd';
import http from "../../lib/service/http";
import locale from 'antd/es/date-picker/locale/zh_CN';
import NoData from "../../components/NoData";
import {localTime} from "../../lib/help/helper";
const {RangePicker} = DatePicker;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 24},
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
}


export default class OperationLog extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      columns: [
        {
          title: 'UID',
          dataIndex: 'ID',
          key: 'ID',
        },
        {
          title: '用户名',
          dataIndex: 'Operator',
          key: 'Operator',
        },
        {
          title: '手机号',
          dataIndex: 'Telephone',
          key: 'Telephone',
        },
        {
          title: 'IP地址',
          key: 'OperatorIP',
          dataIndex: 'OperatorIP',
        },
        {
          title: '操作',
          dataIndex: 'Operation',
          key: 'Operation'
        },
        {
          title: '操作时间',
          dataIndex: 'CreatedAt',
          key: 'CreatedAt',
          render: (time) => {
            return localTime(time)
          }
        },
      ],
      total: 0,
      currentPage: 1,
      visible: false,
      isModalVisible: false,
      modalType: 'enable',
      userId: '',
      searchInfo: {
        query_username: '',
        startDate: '',
        endDate: '',
      },
      loginInfo: {}
    }
  }

  componentDidMount() {
    this.getListData()
    let tmpInfo = sessionStorage.getItem('loginInfo') || '{}'
    tmpInfo = JSON.parse(tmpInfo);
    this.setState({
      loginInfo: tmpInfo
    })
  }

  componentWillUnmount() {
  }

  getListData = () => {
    let params = this.state.searchInfo;
    params.page = this.state.currentPage;
    http.get('/operation-log/list', params).then(res => {
      this.setState({
        dataList: res.data.items ? res.data.items : [],
        total: res.data.total,
        page: res.data.total_page,
        currentPage: res.data.current_page
      });
    })
  }
  onChangePage = (page) => {
    this.setState({
      currentPage: page,
    }, () => {
      this.getListData()
    });
  }

  onFinish(value) {
    let params = {}
    if (value.query_username) params.query_username = value.query_username;
    if (value.range_picker) {
      params.query_time_start = value.range_picker[0].format('YYYY-MM-DD')+" 00:00:00";
      params.query_time_end = value.range_picker[1].format('YYYY-MM-DD')+" 23:59:59";
    }
    this.setState({
      currentPage: 1,
      searchInfo: {
        ...params
      }
    }, ()=> {
      this.getListData();
    })
  }

  onFinishFailed(value) {
  }
  resetSearch = () => {
    this.formRef.current.resetFields();
    this.formRef.current.submit();
  };

  render() {
    const {dataList, total, currentPage, columns,loginInfo} = this.state;
    return (
      <div className="admin-user-container main-container">
        <div className="admin-user-content">
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            className="form-content search-form-content"
            ref={this.formRef}
            onFinish={(values) => this.onFinish(values)}
            onFinishFailed={() => this.onFinishFailed()}
          >
            <Row gutter={24}>
              {loginInfo.role==='SUPER-ADMIN'&& <Col xl={8} lg={12}>
                <Form.Item
                  {...formItemLayout}
                  label="用户名"
                  name="query_username"
                  className={'search-item'}
                >
                  <Input placeholder={'可按关键字模糊搜索'}/>
                </Form.Item>
              </Col>}
              <Col xl={9} lg={12}>
                <Form.Item
                  {...formItemLayout}
                  name="range_picker"
                  label="操作时间"
                  className={'not-extra date-time search-item'}
                >
                  <RangePicker locale={locale} format="YYYY-MM-DD"/>
                </Form.Item>
              </Col>
              <Col xl={7} lg={12} className="edit-btn-box">
                <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                  <Button className={'submit-btn'} type="primary" htmlType="submit">
                    搜索
                  </Button>
                </Form.Item>
                <Form.Item {...tailLayout} className="reset-btn btn-submit">
                  <Button className={'edit-btn'} onClick={()=>this.resetSearch()}>
                    重置
                  </Button>
                </Form.Item>

              </Col>
            </Row>


          </Form>
          {
            dataList.length > 0 ?
              <div className="list-box">
                <Table rowKey={'ID'} columns={columns} dataSource={dataList} pagination={false}/>
                <Pagination
                  className={'page-style'}
                  onChange={this.onChangePage}
                  defaultCurrent={1}
                  total={total}
                  pageSize={10}
                  current={currentPage}
                  showSizeChanger={false}
                  showTotal={total => `共${total}条`}
                />
              </div> :
              <div className="list-box">
                <NoData/>
              </div>
          }

        </div>
      </div>
    )
  }
}
