import React from 'react';
import {Row, Col, Modal, Form, Button, Input, message} from "antd";
import http from "../../lib/service/http";
import {getImgUrl} from '../../lib/help/helper'
import {rules} from '../../lib/help/rules'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import imgQiye from "../../resource/images/public/img_qiyefuwu.svg"

const {not_null} = rules;
const {TextArea} = Input;
export default class CompanyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '发布驳回',
      detailList: {},
      visible: false,
      isModalVisible: false,
      btnState: '',
    }
  }

  getListData() {
    http.get('/product/service-detail/' +  this.props.props.match.params.id).then(res => {
      this.setState({
        detailList: res.detail,
        status: res.detail.Status
      });
    });
  };

  componentDidMount() {
    this.getListData();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  pass = () => {
    let params = {
      product_id: Number(this.props.props.match.params.id),
      product_type: 'SERVICE',
      audit_status: 'PUBLISHED',
    };
    const that = this;
    http.post('/product/audit', params).then(res => {
      message.success({
        content: '审核成功',
      });
      that.props.props.history.push('/product/company-list');
    }, () => {

    })
  };
  reject = () => {
    this.setState({
      visible: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      isModalVisible: false,
    });
  };
  onFinish = (values) => {
    const that = this;
    let params = {
      product_id: Number(this.props.props.match.params.id),
      product_type: 'SERVICE',
      audit_status: 'PUBLISH-REJECTED',
      reject_reason: values.reject_reason,
    };
    http.post('/product/audit', params).then(res => {
      message.success({
        content: '审核成功',
      });
      that.props.props.history.push('/product/company-list');
    }, () => {

    });
    this.setState({
      visible: false,
    });
  };

  onFinishFailed(errorInfo) {
  };
  editProjectConfirm = () => {
    let params = {
      product_id: this.state.detailList.ID,
      product_type: 'SERVICE',
    }
    http.post(`/product/shelve`, params).then(res => {
      if (res) {
        message.success('操作成功');
        this.props.props.history.replace('/product/company-list')
      }
    })
  }
  showShelveModal(type) {
    this.setState({
      btnState: type,
      isModalVisible: true,
    })
  }

  render() {
    const {detailList, visible, isModalVisible, btnState} = this.state;
    return (
      <div className="admin-user-container debt-finance main-container">
        <div className="debt-content debt-detail admin-user-content">
          <div className={'debt-detail-content'}>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>产品名称</Col>
              <Col span={21} className={'name-text'}>{detailList.Name}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>类型</Col>
              <Col span={21} className={'name-text'}>{detailList.ProductTypeStr}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>图片</Col>
              <Col span={21} className={'name-text name-img'}>
                {
                  detailList.ImageFid ?
                    <img src={getImgUrl(detailList.ImageFid)} alt=""/> : <img src={imgQiye} alt=""/>
                }
              </Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>价格区间</Col>
              <Col span={21}
                   className={'name-text'}>{detailList.PriceFloor}-{detailList.PriceCeil}万元</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>联系方式</Col>
              <Col span={21} className={'name-text'}>{detailList.Contact}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>项目介绍</Col>
              <Col span={21} className={'name-text name-text-other'}>{detailList.Description}</Col>
            </Row>
          </div>
          <div className={'debt-row'}>
            {
              this.state.status === 'REVIEW-PASS' &&
              <Row>
                <Col span={3} className={'name-title'}/>
                <Col span={21} className={'name-text'}>
                  <div className={'btn-operate'}>
                    <span className="btn btn-pass" onClick={() => this.pass()}>通过</span>
                    <span className="btn btn-reject" onClick={() => this.reject()}>驳回</span>
                  </div>
                </Col>
              </Row>
            }
            {
              this.state.status === 'PUBLISHED' &&
              <Row>
                <Col span={3} className={'name-title'}/>
                <Col span={21} className={'name-text'}>
                  <div className={'btn-operate'}>
                    <span className="btn btn-pass" onClick={() => this.showShelveModal('off')}>下架</span>
                  </div>
                </Col>
              </Row>
            }
            {
              this.state.status === 'SHELVED' &&
              <Row>
                <Col span={3} className={'name-title'}/>
                <Col span={21} className={'name-text'}>
                  <div className={'btn-operate'}>
                                        <span className="btn btn-pass"
                                              onClick={() => this.showShelveModal('on')}>恢复</span>
                  </div>
                </Col>
              </Row>
            }
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          cancelText={'取消'}
          okText={'确定'}
          centered
          onOk={this.editProjectConfirm}
          className="set-padding"
          onCancel={this.handleCancel}>
          <p className='account-modal-title logout-title'>
            <ExclamationCircleOutlined className="icon-waring"/>
            确定{btnState === 'off' ? '下架' : '恢复'}该产品吗?</p>
          <p className="account-modal-desc">
            {btnState === 'off' ? '下架后的产品将不再显示在前台网站中' : '恢复后的产品将重新显示在前台网站中'}
          </p>
        </Modal>
        <Modal title={'审核产品'}
               visible={visible}
               onCancel={this.handleCancel}
               onOk={this.handleCancel}
               okText={'确定'}
               footer={null}
               width={640}
               wrapClassName='modal-reject'
               destroyOnClose={true}
        >
          <Form
            name="basic"
            ref={this.formRef}
            onFinish={(values) => this.onFinish(values)}
            onFinishFailed={() => this.onFinishFailed()}>
            <Form.Item
              label="驳回原因"
              name="reject_reason"
              rules={not_null}
              validateFirst={true}
            >
              <TextArea size="middle" placeholder={'请输入驳回原因'}/>
            </Form.Item>
            <Form.Item className="footer-btn">
              <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                取消
              </Button>
              <Button className={'submit-btn'} type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
