import React from 'react';
import {withRouter} from "react-router-dom";
import http from "../../lib/service/http";
import './index.scss';
import {Row, Col} from 'antd';
import {getImgUrl, localTime} from "../../lib/help/helper";
import vector from "../../resource/images/public/vector.svg";
import time from "../../resource/images/public/time.svg"
import rectangle from "../../resource/images/public/rectangle.svg"
import companyLogo from "../../resource/images/public/img_qiyefuwu.svg"
class CorporateDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {},
      contact: '',
      hotDebtList: [],
      userInfo: {},
    }
  }
  getListData(params) {
    http.get(`product-order/detail/${params}`).then(res => {
      this.setState({
        detailList: res.data.product_object,
        orderObject: res.data.order_object
      });
    })
  };
  getContact() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    let user = JSON.parse(userInfo).user;
    if (user) {
      const params = {
        id: Number(this.props.match.params.id),
        product_type: "SERVICE",
      };
      http.post('/home/product-contact', params).then(res => {
        if(res) {
          this.setState({
            contact: res.contact
          })
        }
      }, () => {
      });
    } else {
      this.setState({
        contact: '******'
      })
    }
  };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id);
    this.getContact()
  }
  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {detailList} =  this.state;
    return (
      <div className="firm-register-container product-detail-container main-container">
        <div className="firm-register-content content">
              <Row className={'detail-info part-one'}>
                <Col span={4} className={'img-box'}>
                  {detailList.ImageFid?<img src={getImgUrl(detailList.ImageFid)} alt="" />: <img src={companyLogo} alt=""/>}
                </Col>
                <Col span={20} className={'info-box'}>
                  <div className={'title'}>{detailList.Name}</div>
                  <Row className={'bond-info'}>
                    <Col span={12}>
                      <div className={"fin-name"}>
                        <img src={vector} className={'vector-img'} alt=""/>
                        <span> {detailList.FinancialName}</span>
                      </div>
                    </Col>
                    <Col span={12}><img src={time} className={'vector-img'} alt=""/>{localTime(detailList.CreatedAt)}</Col>
                  </Row>
                  <Row className={'range-info range-service'}>
                    <Col span={11} >
                      <ul>
                        <li className={'title-num title-rate'}>{detailList.PriceFloor}-{detailList.PriceCeil}万元</li>
                        <li className={'title-diff'}>价格区间</li>
                      </ul>
                    </Col>
                    <Col span={1} className={'range-service-col'}/>
                    <Col span={12}>
                      <ul>
                        <li className={'title-num'}>{detailList.ProductTypeStr}</li>
                        <li className={'title-diff'}>服务类别</li>
                      </ul>
                    </Col>
                    </Row>
                </Col>
                <div className={'product-info'}>
                  <div className={'product-features'}>
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>项目介绍</span></div>
                    <div className={'service-introduction'}>{detailList.Description}</div>
                    {detailList.Remark&&detailList.Remark.length>0?(<div>
                      <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>其他说明</span></div>
                      <div className={'service-introduction'}>{detailList.Remark}</div>
                    </div>):(<div/>)}
                    <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>联系方式</span></div>
                    <div className={'service-introduction'}>{detailList.Contact}</div>
                  </div>
                </div>
              </Row>
        </div>
      </div>
    )
  }
}
export default withRouter(CorporateDetail)
