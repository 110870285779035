import React from 'react';
import {withRouter} from "react-router-dom";
import http from "../../lib/service/http";
import './index.scss';
import { Row, Col} from 'antd';
import {getImgUrl, localTime} from "../../lib/help/helper";
import vector from "../../resource/images/public/vector.svg";
import time from "../../resource/images/public/time.svg"
import rectangle from "../../resource/images/public/rectangle.svg"
import jigouLogo from "../../resource/images/public/jigou_logo.svg"
class BondDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailList: {},
      contact: '',
      hotDebtList: [],
      userInfo: {},
      orderObject: {},
      financialObject: {}
    }
  }
  getListData(params) {
    http.get(`product-order/detail/${params}`).then(res => {
      this.setState({
        detailList: res.data.product_object,
        orderObject: res.data.order_object,
        financialObject:res.data.financial_object
      });
    })
  };
  getValueByKey=(key,type)=> {
    let array = [];
    const arrayPickerData = [];
    if (type&&type===1) {
      array = [
        {'信用':'CREDIT'},
        {'保证':'ENSURE'},
        {'抵押':'MORTGAGE'},
        {'质押':'PLEDGE'}
      ];
    } else {
      array = [
        {'CREDIT':'信用'},
        {'ENSURE':'保证'},
        {'MORTGAGE':'抵押'},
        {'PLEDGE':'质押'}
      ];
    }
    if (key!==undefined) {
      let keyArr = key.split(",");
      keyArr.forEach((k)=>{
        array.forEach((Item) => {
          if (Item[k]!==undefined) {
            arrayPickerData.push(Item[k]);
          }
        });
      });
      return arrayPickerData.join(',');
    }
  };
  componentWillMount () {
    const id = this.props.match.params.id;
    this.getListData(id);
  }
  render() {
    let userInfo = sessionStorage.getItem('userInfo') || '{}';
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const {detailList,financialObject} =  this.state;
    return (
      <div className="admin-user-container product-detail-container main-container">
        <div className="firm-register-content content">
          <Row className={'detail-info part-one'}>
            <Col span={4} className={'img-box'}>
              {financialObject.LogoFid? <img src={getImgUrl(financialObject.LogoFid)} alt="" />: <img src={jigouLogo} alt=""/>}
            </Col>
            <Col span={20} className={'info-box'}>
              <div className={'title'}>{detailList.Name}</div>
              <Row className={'bond-info'}>
                <Col span={12}>
                  <div className={"fin-name"}>
                    <img src={vector} className={'vector-img'} alt=""/>
                    <span> {detailList.FinancialName}</span>
                  </div>
                </Col>
                <Col span={12}><img src={time} className={'vector-img'} alt=""/>{localTime(detailList.CreatedAt)}</Col>
              </Row>
              <div className={'service'}>
                <div><span className={'area'}>服务地区: </span> {detailList.Area}</div>
                {/*<div><span className={'area'}>有效期: </span> {detailList.Validity === 'FIXED' ? (localDate(detailList.ValidityStart) + '-- '+ localDate(detailList.ValidityEnd)):detailList.Validity}</div>*/}
              </div>
              <Row className={'range-info'}>
                <Col span={6}>
                  <ul>
                    <li className={'title-num title-rate'}>{detailList.RateCeil? detailList.RateFloor+"%-"+detailList.RateCeil+"%":detailList.RateFloor+"%以上"}</li>
                    <li className={'title-diff'}>利率范围</li>
                  </ul>
                </Col>
                <Col span={6}>
                  <ul>
                    <li className={'title-num'}>{detailList.CycleCeil? detailList.CycleFloor +"-"+detailList.CycleCeil+"个月":detailList.CycleFloor+"个月以上"}</li>
                    <li className={'title-diff'}>期限范围</li>
                  </ul>
                </Col>
                <Col span={6}>
                  <ul>
                    <li className={'title-num'}>{detailList.AmountCeil? detailList.AmountFloor +"-"+detailList.AmountCeil+"万元":detailList.AmountFloor+"万元以上"}</li>
                    <li className={'title-diff'}>金额范围</li>
                  </ul>
                </Col>
                <Col span={6}>
                  <ul>
                    <li className={'title-num'}>{this.getValueByKey(detailList.Secured)}</li>
                    <li className={'title-diff'}>担保方式</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <div className={'product-info'}>
              <div className={'product-features'}>
                <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>产品特点</span></div>
                <div className={'features-one'}>
                  {/*<div className={'time-limit'}>1.期限长</div>*/}
                  <div className={'time-text'}>
                    <div dangerouslySetInnerHTML={{__html: detailList.Description}} /></div>
                </div>
                {/*<div className={'features-one'}>*/}
                {/*  <div className={'time-limit'}>2.还款灵活</div>*/}
                {/*  <div className={'time-text'}>xxxxxx</div>*/}
                {/*</div>*/}
                {/*<div className={'features-one'}>*/}
                {/*  <div className={'time-limit'}>3.核批快</div>*/}
                {/*  <div className={'time-text'}>xxxxxx</div>*/}
                {/*</div>*/}
                {/*<div className={'features-one'}>*/}
                {/*  <div className={'time-limit'}>4.需贷简</div>*/}
                {/*  <div className={'time-text'}>xxxxxx</div>*/}
                {/*</div>*/}
                <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>申请条件</span></div>
                <div className={'features-one'}>
                  <div className={'time-text'}>{detailList.Requirement}</div>
                </div>
                <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>所需材料</span></div>
                <div className={'features-one'}>
                  {/*<div className={'time-limit'}>1.期限长</div>*/}
                  {/*<div className={'time-limit'}>1.期限长</div>*/}
                  {/*<div className={'time-limit'}>1.期限长</div>*/}
                  <div className={'time-text'}>{detailList.Data}</div>
                </div>
                {detailList.Remark&&detailList.Remark.length>0?(<div>
                  <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>其他说明</span></div>
                  <div className={'features-one'}>
                    <div className={'time-text'}>{detailList.Remark}</div>
                  </div>
                </div>):(<div/>)}
                <div className={'product-img'}><img src={rectangle} alt=""/> <span className={'product-title'}>联系方式</span></div>
                <div className={'features-one'}>
                  <div className={'time-text'}>{detailList.Contact}</div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}
export default withRouter(BondDetail)
