import React from 'react';
import RecordIndex from '../../common/Record'
import http from "../../lib/service/http";
import {Col, Row} from "antd";
import {localTime} from "../../lib/help/helper";
export default class Record extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordData: {},
      orderObject: {},
      productObject: {},
    }
  }
  getListData(params) {
    http.get(`product-order/detail/${params}`).then(res => {
      this.setState({
        recordData: res.data.time_line_data,
        orderObject: res.data.order_object,
        productObject: res.data.product_object,
      });
    })
  };
  componentWillMount () {
    const id = this.props.props.match.params.id;
    this.getListData(id);
  }

  render() {
    const {orderObject,productObject} = this.state;
    return (
      <div className="admin-user-container debt-finance main-container">
        {(orderObject.OrderStatus ==='MATCH-FAILED'||orderObject.OrderStatus ==='EXPIRED'||
          orderObject.OrderStatus ==='CLOSED')&&
        <div className={'transaction-result-box'} style={{width: '50%'}}>
          <div className={'transaction-result'}>交易结果:{(orderObject.OrderStatus ==='MATCH-FAILED' ?'撮合失败':
            orderObject.OrderStatus ==='EXPIRED'?'已失效':'已关闭')}</div>
          <div className={'apply-transaction-result'}>{(orderObject.IntentAmount&&orderObject.IntentAmount.length>0?<div>
              <span>预算金额:</span> <span  className={'apply-transaction-result-text'}>{orderObject.IntentAmount}万元</span>
            </div> :
            '')}</div>
          <div className={'apply-transaction-result'}>{(orderObject.Memo&&orderObject.Memo.length>0? <div className={'apply-transaction-result-container'}>
              <div className={'memo'}>备注:</div><span className={'apply-transaction-result-text'}>{orderObject.Memo}</span>
            </div>:
            '')}</div>
          <Row className={'transaction-result-row row-title'}>
            <Col span={4}>订单编号</Col>
            <Col span={6}>申请企业</Col>
            <Col span={8}>发布机构</Col>
            <Col span={6}>交易产品</Col>
          </Row>
          <Row className={'transaction-result-row row-result'}>
            <Col span={4}>{orderObject.ID}</Col>
            <Col span={6}>{orderObject.EnterpriseName}</Col>
            <Col span={8}>{productObject.FinancialName}</Col>
            <Col span={6}>{productObject.Name}</Col>
          </Row>
        </div>}
        {(orderObject.OrderStatus ==='MATCH-SUCCESS' ||orderObject.OrderStatus ==='PENDING-MATCH' ||
          orderObject.OrderStatus ==='PENDING-DISTRIBUTE')&&
        <div className={'transaction-result-box'}>
          <div className={'transaction-result'}>交易结果:{(orderObject.OrderStatus ==='MATCH-SUCCESS' ?'撮合成功':
            orderObject.OrderStatus ==='PENDING-MATCH'?'待撮合':'待分配')}</div>
          <div className={'apply-transaction-result'}>{(orderObject.IntentAmount&&orderObject.IntentAmount.length>0?<div>
              <span>预算金额:</span> <span  className={'apply-transaction-result-text'}>{orderObject.IntentAmount}万元</span>
          </div> :
          '')}</div>
          <div className={'apply-transaction-result'}>{(orderObject.Memo&&orderObject.Memo.length>0? <div className={'apply-transaction-result-container'}>
              <div className={'memo'}> 备注:</div><span className={'apply-transaction-result-text'}>{orderObject.Memo}</span>
            </div>:
            '')}</div>
          <Row className={'transaction-result-row row-title'}>
            <Col span={4}>订单编号</Col>
            <Col span={4}>申请企业</Col>
            <Col span={4}>发布机构</Col>
            <Col span={4}>交易产品</Col>
            <Col span={4}>交易时间</Col>
            <Col span={4}>价格</Col>
          </Row>
          <Row className={'transaction-result-row row-result'}>
            <Col span={4}>{orderObject.ID}</Col>
            <Col span={4}>{orderObject.EnterpriseName}</Col>
            <Col span={4}>{productObject.FinancialName}</Col>
            <Col span={4}>{productObject.Name}</Col>
            <Col span={4}>{localTime(orderObject.MatchedAt)}</Col>
            <Col span={4}>{orderObject.Amount?orderObject.Amount+'万元':'--' }</Col>
          </Row>
        </div>}
        <RecordIndex recordData={this.state.recordData}/>
      </div>
    )
  }
}
