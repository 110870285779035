import React from 'react';
import {Form, Input, Button, DatePicker, Row, Col, Modal, Pagination, Table, message} from 'antd';
import './index.scss'
import http from "../../lib/service/http";
import locale from 'antd/es/date-picker/locale/zh_CN';
import Add from './Modal/AddUser';
import NoData from "../../components/NoData";
import {localTime} from "../../lib/help/helper";
import { ExclamationCircleOutlined } from "@ant-design/icons";


const {RangePicker} = DatePicker;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}


export default class AdminUser extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            columns: [
                {
                    title: 'UID',
                    dataIndex: 'ID',
                    key: 'ID',
                },
                {
                    title: '用户名',
                    dataIndex: 'UserName',
                    key: 'UserName',
                },
                {
                    title: '手机号',
                    dataIndex: 'Telephone',
                    key: 'Telephone',
                },
                {
                    title: '角色',
                    key: 'RoleStr',
                    dataIndex: 'RoleStr',
                },
                {
                    title: '备注',
                    dataIndex: 'Remark',
                    key: 'Remark',
                    render: (text) => {
                        return text ? text : '- -'
                    }
                },
                {
                    title: '生成时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                },
            ],
            total: 0,
            currentPage: 1,
            visible: false,
            isModalVisible: false,
            modalType: 'enable',
            userId: '',
            searchInfo: {
                keywords: '',
                startDate: '',
                endDate: '',
            }
        }
    }

    componentDidMount() {
        this.getListData()
    }

    componentWillUnmount() {
    }

    getListData = () => {
        let params = this.state.searchInfo;
        params.page = this.state.currentPage;
        http.get('/admin/list', params).then(res => {
            this.setState({
                dataList: res.data.items ? res.data.items : [],
                total: res.data.total,
                page: res.data.total_page,
                currentPage: res.data.current_page
            });
        })
    }
    onChangePage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getListData()
        });
    }

    onFinish(value) {
        let params = {}
        if (value.keywords) params.keywords = value.keywords
        if (value.range_picker) {
            params.startDate = value.range_picker[0].format('YYYY-MM-DD')+" 00:00:00";
            params.endDate = value.range_picker[1].format('YYYY-MM-DD')+" 23:59:59";
        }
        this.setState({
            currentPage: 1,
            searchInfo: {
                ...params
            }
        }, ()=> {
            this.getListData();
        })

    }

    onFinishFailed(value) {
    }

    addUser = () => {
        this.setState({
            visible: true,
        });
    };
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }
    closeModal = () => {
        this.setState({
            visible: false,
            currentPage: 1
        }, ()=> {
            this.getListData()
        });
    };
    editBtn = (type, userId) => {
        this.setState({
            modalType: type,
            isModalVisible: true,
            userId: userId
        })
    }
    handleOk = () => {
        let params = {
            user_id: this.state.userId
        }
        http.post('/admin/disable', params).then(() => {
            this.setState({
                currentPage: 1
            }, ()=> {
                this.getListData({page: 1})
                message.success(this.state.modalType === 'enable' ? '启用成功' : '禁用成功');
                this.confirmHandleCancel();
            })
        })
    }
    confirmHandleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    resetSearch = () => {
        this.formRef.current.resetFields();
        this.formRef.current.submit();
    }

    setListTab() {
        let userInfo = sessionStorage.getItem('adminUserInfo')
        if(userInfo) {
            userInfo = JSON.parse(userInfo);
            if(userInfo.user.Role === 'SUPER-ADMIN') {
                return this.state.columns.concat([{
                    title: '操作',
                    dataIndex: 'Disabled',
                    key: 'Disabled',
                    render: (Disabled, rows) => (
                        <div>
                            {
                                rows.Role === 'SUPER-ADMIN' ?
                                    <p>- -</p> :
                                    Disabled ? <p className='table-edit-btn'
                                                  onClick={() => this.editBtn('enable', rows.ID)}>启用</p> :
                                        <p className={'table-edit-btn'}
                                           onClick={() => this.editBtn('disabled', rows.ID)}>禁用</p>
                            }
                        </div>

                    ),
                }])
            }else {
                return this.state.columns;
            }
        }
    }

    render() {
        const {dataList, total, currentPage, visible, isModalVisible, modalType} = this.state;
        return (
            <div className="admin-user-container main-container">
                <div className="admin-user-content">
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        className="form-content search-form-content"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Row gutter={24}>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="用户名"
                                    name="keywords"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={9} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    name="range_picker"
                                    label="生成时间"
                                    className={'not-extra date-time search-item'}
                                >
                                    <RangePicker locale={locale} format="YYYY-MM-DD"/>
                                </Form.Item>
                            </Col>
                            <Col xl={7} lg={12} className="edit-btn-box">
                                <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                                    <Button className={'submit-btn'} type="primary" htmlType="submit">
                                        搜索
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} className="reset-btn btn-submit">
                                    <Button className={'edit-btn'} onClick={()=>this.resetSearch()}>
                                        重置
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} className="add-btn-box btn-submit">
                                    <Button className={'edit-btn'} onClick={() => this.addUser()}>
                                        新增管理员
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>


                    </Form>
                    {
                        dataList.length > 0 ?
                            <div className="list-box">
                                <Table rowKey={'ID'} columns={this.setListTab()} dataSource={dataList} pagination={false}/>
                                <Pagination
                                    className={'page-style'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={10}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    showTotal={total => `共${total}条`}
                                />
                            </div> :
                            <div className="list-box">
                                <NoData/>
                            </div>
                    }

                </div>
                <Modal title={'新增管理员'}
                       visible={visible}
                       onCancel={this.handleCancel}
                       onOk={this.handleCancel}
                       okText={'确定'}
                       footer={null}
                       width={640}
                       wrapClassName='modal-add-user modal-add-content'
                       destroyOnClose={true}
                >
                    <Add close={this.closeModal}/>
                </Modal>
                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.handleOk}
                    className="set-padding"
                    onCancel={this.confirmHandleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring"/>
                        确定{modalType === 'enable' ? '启用' : '禁用'}该账户吗?</p>
                    <p className='account-modal-desc'>{modalType === 'enable' ? '启用后该账号将恢复权限' : '禁用后该账号将无法登录'}</p>
                </Modal>
            </div>
        )
    }
}
