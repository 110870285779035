import React from 'react';
import {Button, Form, Input, message} from "antd";
import http from "../../../lib/service/http";
import {rules} from "../../../lib/help/rules";

const {account, password, phone, add_user_remark} = rules;
const {TextArea} = Input;
export default class AddUser extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
            roleType: []
        }
    }

    handleCheckPwd = (_, value, callback) => {
        let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
        if (cfmPwd && value && cfmPwd !== value) {
            callback(new Error('两次密码不一致'))

        } else {
            callback()
        }
    };

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            callback()
        }
    };
    onFinish = (values) => {
        http.post('/admin/create', values).then(res => {
            if (Object.keys(res).length === 0) {
                this.props.close();
                message.success('新增成功')
            }
        }, () => {
        })
    };

    componentDidMount() {
    }

    onFinishFailed(errorInfo) {
    };

    close = () => {
        this.props.close();
    };
    preventBubble = (e) => {
        e.preventDefault();
    };

    render() {
        const {confirmPasswordRules, passwordRules} = this.state;
        return (
            <div className="modal-add-content">
                <div className="form-content add-from-content">
                    <div className="left-from">
                        <Form
                            name="basic"
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}>
                            <Form.Item
                                label="用户名称"
                                name="username"
                                rules={account}
                                extra={'6-20位字符，含数字、字母中的一种及以上'}
                                validateFirst={true}
                            >
                                <Input size="middle" placeholder={'请输入用户名称'}/>
                            </Form.Item>

                            <Form.Item
                                label="初始密码"
                                name="password"
                                rules={passwordRules}
                                extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                                className={'input-password'}
                                validateFirst={true}
                            >
                                <Input.Password size="middle" placeholder={'请输入密码'}/>
                            </Form.Item>

                            <Form.Item
                                label="确认密码"
                                name='confirm_password'
                                rules={confirmPasswordRules}
                                className="confirm-input not-extra input-password"
                                validateFirst={true}
                            >
                                <Input.Password size="middle" placeholder={'请再次输入密码'}/>
                            </Form.Item>

                            <Form.Item
                                label="手机号"
                                name="telephone"
                                rules={phone}
                                className="not-extra input-phone"
                            >
                                <Input size="middle" placeholder={'请注意，忘记密码时需验证手机号才可重置密码'}/>
                            </Form.Item>

                            <Form.Item
                                label="备注"
                                name="remark"
                                rules={add_user_remark}
                                className="not-extra input-remark"
                            >
                                <TextArea size="middle" placeholder={'请输入备注信息'}
                                          onPressEnter={(e) => this.preventBubble(e)}/>
                            </Form.Item>
                            <Form.Item className="footer-btn">
                                <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.close}>
                                    取消
                                </Button>
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
