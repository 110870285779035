import React from 'react';
import {Tabs} from "antd";
import './index.scss'
import Detail from "./detail";
import Record from "./record"
const {TabPane} = Tabs;
export default class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey:  '1'
    }
  }
  changeTab=(key)=> {
    this.setState({
      activeKey: key
    })
  };
  render() {
    return (
      <div className={'product-info'}>
        <Tabs defaultActiveKey='1' className={'tab-box'} onChange={this.changeTab}>
          <TabPane tab={
            <p className="tab-item tab-item-1">产品详情</p>
          } key="1">
            {this.state.activeKey === '1'?  <Detail props={this.props}/>: <div/>}
          </TabPane>
          <TabPane tab={
            <p className="tab-item tab-item-2">交易记录</p>
          } key="2">
            {this.state.activeKey === '2'?  <Record props={this.props} />: <div/>}
          </TabPane>
        </Tabs>
      </div>
    )
  }
}
