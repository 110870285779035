import React from 'react';
import {Layout, Menu} from 'antd';

import './index.scss'
import {Link, withRouter} from "react-router-dom";
import { AppstoreFilled, HomeFilled, ContainerFilled, ProjectFilled, SketchSquareFilled, SettingFilled,PayCircleFilled} from '@ant-design/icons';
import http from "../../lib/service/http";


const {SubMenu} = Menu;
const {Sider} = Layout;
class Aside extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            openKeys: ['/'+this.props.history.location.pathname.split('/')[1]],
            defaultSelectedKeys: ['/index'],
            currentUrl: this.props.history.location.pathname,
            productFinancialAmount: 0,
            productServiceAmount: 0,
            total:0,
            timeVal: ''
        }
    }

  getCount=()=> {
      let tmpVal = setInterval(()=> {
          this.getListCount()
      },5000);
      this.setState({
          timeVal: tmpVal
      })
  };
    getListCount = () => {
        let config = {
            isLoading: false
        };
        http.get('/overview/product-review-pass-count',{},config).then(res => {
            if (res) {
                this.setState({
                    productFinancialAmount: res.data.product_financial_amount,
                    productServiceAmount: res.data.product_service_amount,
                    total: res.data.product_service_amount + res.data.product_financial_amount
                })
            }
        })
    }

    componentDidMount() {
        this.getCount();
        this.getListCount();
        if(this.props.history.location.pathname && this.props.history.location.pathname.split('/').length >= 4) {
            this.setState({
                currentUrl: this.props.history.location.pathname.split('/').slice(0, 3).join('/')
            })
        }
        this.props.history.listen(route => {
            let tmpUrl = route.pathname;
            if(route.pathname && route.pathname.split('/').length >= 4) {
                tmpUrl = route.pathname.split('/').slice(0, 3).join('/')
            }
            this.setState({
                openKeys: this.getOpenKeys(this.props.history.location.pathname.split('/')),
                currentUrl: tmpUrl
            })
        })

    }
    componentWillUnmount () {
        this.setState = ()=>false;
        clearInterval(this.state.timeVal);
    }


    onOpenChange = openKeys => {
        this.setState({
            openKeys: [openKeys[openKeys.length - 1]]
        })
    };
    setSelected(item) {
        if (item.key === '/index') {
            this.setState({
                currentUrl: item.key,
                openKeys: ['/index']
            })
        } else {
            this.setState({
                currentUrl: item.key
            })
        }

    }

    getOpenKeys = (keys) => {
        if (keys.length >= 3) {
            return keys.filter((item, i) => i > 0 && i < keys.length - 1).map(item => '/' + item)
        } else {
            return ['/' + keys[1]]
        }
    }


    render() {
        return (
            <Sider
                collapsedWidth="0"
                onBreakpoint={broken => {
                }}
                onCollapse={(collapsed, type) => {
                }}
                width={240}
                className={'aside-container'}
            >
                <div className="logo-box">
                    {/*<img src={logo} alt="" className="aside-logo" />*/}
                  {/*LOGO*/}
                </div>
                <div className="aside-menu-box">
                    <Menu
                        ref={this.menuNav}
                        mode="inline"
                        openKeys={this.state.openKeys}
                        defaultSelectedKeys={this.state.defaultSelectedKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ width: '100%' }}
                        selectedKeys={this.state.currentUrl}
                        onClick={(item) => this.setSelected(item)}
                    >
                        <Menu.Item key="/index" className={'home-item'} icon={<HomeFilled />}>
                            <Link to={'/index'} >首页</Link>
                        </Menu.Item>
                        <SubMenu
                            key="/system"
                            icon={<AppstoreFilled />}
                            title={
                                <span>
                                    <span>系统管理</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/system/invite-code">
                                <Link to={'/system/invite-code'}>邀请码管理</Link>
                            </Menu.Item>
                            <Menu.Item key="/system/user-list">
                                <Link to={'/system/user-list'}>管理员管理</Link>
                            </Menu.Item>
                            <Menu.Item key="/system/operation-log">
                                <Link to={'/system/operation-log'}>操作日志</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="/order" icon={<ContainerFilled />}  title={
                            <span>
                                <span>订单管理</span>
                            </span>
                        }>
                            <Menu.Item key="/order/financing-list">
                                <Link to={'/order/financing-list'}>贷款融资</Link>
                            </Menu.Item>
                            <Menu.Item key="/order/company-list">
                                <Link to={'/order/company-list'}>企业服务</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="/product" icon={<ProjectFilled />}  title={
                            <span>
                                <span>产品管理</span>{this.state.total !== 0 ? <span className={'badge'}>{this.state.total}</span> : ''}
                            </span>
                        }>
                            <Menu.Item key="/product/financing-list">
                                <Link to={'/product/financing-list'}>贷款融资{this.state.productFinancialAmount !== 0 ? <span className={'badge'}>{this.state.productFinancialAmount}</span> : ''}</Link>
                            </Menu.Item>
                            <Menu.Item key="/product/company-list">
                                <Link to={'/product/company-list'}>企业服务{this.state.productServiceAmount !== 0 ? <span className={'badge'}>{this.state.productServiceAmount}</span> : ''}</Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="/requirementManage" className={'home-item'} icon={<PayCircleFilled />}>
                            <Link to={'/requirementManage'} >融资需求管理</Link>
                        </Menu.Item>
                        <SubMenu key="/user" icon={ <SketchSquareFilled /> }  title={
                            <span>
                                <span>用户管理</span>
                            </span>
                        }>
                            <Menu.Item key="/user/company-list">
                                <Link to={'/user/company-list'}>企业列表</Link>
                            </Menu.Item>
                            <Menu.Item key="/user/financial-list">
                                <Link to={'/user/financial-list'}>机构列表</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="/content" icon={ <SettingFilled /> }  title={
                            <span>
                                <span>内容管理</span>
                            </span>
                        }>
                            <Menu.Item key="/content/banner">
                                <Link to={'/content/banner'}>首页轮播banner</Link>
                            </Menu.Item>
                            <Menu.Item key="/content/news">
                                <Link to={'/content/news'}>政策资讯</Link>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </div>
                <div className="aside-footer">
                    Copyright © 2021 <br />
                    重庆市大足区兴农融资担保有限责任公司综合服务平台 <br />
                    渝ICP备 2021002695号
                </div>
            </Sider>

        )
    }
}

export default withRouter(Aside)
