import React from 'react';
import {Timeline} from "antd";
import './index.scss'
import Avatar  from "../../resource/images/public/user-avatar.svg"
import {localTime} from "../../lib/help/helper";
import NoData from "../../components/NoData";
export default class ProductRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordData: {}
    }
  }
  componentWillMount() {
    const data = this.props.recordData;
    this.setState({
      recordData: data
    });
  }

  render() {
    return (
      <div className="admin-user-container main-container">
        <div className={'time-line'}>
          {Object.keys(this.props.recordData).length>0? <Timeline>
            {this.props.recordData.ProductAuditTimeLineCreate&&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>机构提交记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.ProductAuditTimeLineCreate&&this.props.recordData.ProductAuditTimeLineCreate.map((item) => {
              return <Timeline.Item color="#C38B3A">
                <div  className={'review-box'}>
                  <div className={'review-info'}>
                    <div><img src={Avatar} alt="" className={'review-avatar'}/>提交人员：{item.Operator}</div>
                    {/*<div className={'review-result'}>审核结果：下架</div>*/}
                    {/*<div className={'review-result'}>审核结果：下架</div>*/}
                  </div>
                  <div className={'review-time'}>
                    提交时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            {this.props.recordData.ProductAuditTimeLineFinancialAudit&&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>机构审核记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.ProductAuditTimeLineFinancialAudit&&this.props.recordData.ProductAuditTimeLineFinancialAudit.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  <div className={'review-info-product'}>
                    <div> <img src={Avatar} alt="" className={'review-avatar'}/>审核人员：{item.Operator}</div>
                    <div className={'operate-result'}>审核结果：{item.OperationNext}</div>
                    {item.OperationNext==='审核驳回'?( <div className={'operate-result'}>驳回原因：{this.props.detail.RejectReason}</div>):(<div/>)}
                  </div>
                  <div className={'review-time'}>
                    审核时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            {this.props.recordData.ProductAuditTimeLineAdminAudit &&
            <Timeline.Item color="#C38B3A" className={'tooltip-timeline'}><div  className={'review-tooltip'}>
              <p className={'review-triangle'}/><p className={'review-triangle-text'}>平台审核记录</p>
            </div></Timeline.Item>
            }
            {this.props.recordData.ProductAuditTimeLineAdminAudit &&this.props.recordData.ProductAuditTimeLineAdminAudit.map((item) => {
              return <Timeline.Item color="#C38B3A" key={item.ID}>
                <div  className={'review-box'}>
                  <div className={'review-info-product'}>
                    <div> <img src={Avatar} alt="" className={'review-avatar'}/>审核人员：{item.Operator}</div>
                    <div className={'operate-result'}>审核结果：{item.Operation ==='恢复产品'?'已恢复上架':item.OperationNext}</div>
                    {item.OperationNext==='发布驳回'?( <div className={'operate-result'}>驳回原因：{this.props.detail.AdminRejectReason}</div>):(<div/>)}
                  </div>
                  <div className={'review-time'}>
                    操作时间：{localTime(item.CreatedAt)}
                  </div>
                </div>
              </Timeline.Item>
            })}
            <Timeline.Item  color="#C38B3A"/>
          </Timeline>: <NoData/>}
        </div>
      </div>
    )
  }
}
