import React from 'react';
import './index.scss'
import {withRouter} from "react-router-dom";
import {Button, Modal, Pagination, Select, Space, Table,message} from "antd";
import NoData from "../../components/NoData";
import {getImgUrl, localTime} from "../../lib/help/helper";
import http from "../../lib/service/http";
const {Option} = Select;
 class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      orderData: [],
      rank: null,
      isEdit: false,
      columns: [
        {
          title: 'banner描述',
          dataIndex: 'Info',
          key: 'Info',
        },
        {
          title: '图片',
          dataIndex: 'ImageFid',
          key: 'ImageFid',
          render: (val) => {
           return  <img src={getImgUrl(val)} alt="" className={'banner-img'}/>
          }
        },
        {
          title: '发布时间',
          dataIndex: 'CreatedAt',
          key: 'CreatedAt',
          render: (time) => {
            return localTime(time)
          }
        },
        {
          title: '序号',
          render: (record) => {
            const arr =  [];
            for (let i = 1; i <= this.state.total; i++) {
              arr.push([i]);
            }
            return (
              <div style={{width: '100px'}}>
                {this.state.isEdit&&(record.Rank===this.state.rank)?( <Select defaultValue={record.Rank} placeholder="请选择"  style={{width: '100px'}} className={'search-select'}  onChange={(e) =>this.onChangeOrder(e,record)}>
                  {
                    arr.map((item) => {
                      return <Option value={item} key={item}>{item}</Option>
                    })
                  }

                </Select>):(<p>{record.Rank}</p>)}
              </div>

            )
          }
        },
        {
          title: '操作',
          render: (text, record) => (
            <Space size="middle" className={'operate-btn'}>
              {this.state.dataList.length>1? <p onClick={this.delete.bind(this, record)}>删除</p>:<div className={'disable-btn'}>删除</div>}
              <p onClick={this.sort.bind(this, record)}>排序</p>
            </Space>
          ),
        },
      ],
      total: 0,
      currentPage: 1,
      userId: '',
    }
  }

   addBanner=()=> {
     this.props.history.push('/content/banner/add?'+ this.state.total);
   };
   onChangeOrder=(e,record)=> {
    let params = {
      id:record.ID,
      rank:e[0]
    };
     const that =this;
     http.post('/banner/rank', params).then(res => {
       message.success({
         content: '编辑成功',
       });
       that.getListData();
       this.setState({
         isEdit: false
       })
     }, () => {

     });
   };
  delete=(record)=> {
    const that = this;
    Modal.confirm({
      className: 'info-disable',
      width: '416px',
      title: (
        <div className={'disable-title'}>确定删除该banner吗?</div>
      ),
      content:(
        <div className={'disable-text'}>删除后将无法撤回</div>
      ),
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
      onOk() {
        http.post('/banner/delete', {id:record.ID}).then(res => {
          if (Object.keys(res).length===0) {
            message.success({
              content: '删除成功',
            });
            that.getListData();
            that.setState({
              isEdit: false
            })
          }
        }, () => {
        })
      },
    });
 };
   sort =(record)=> {
     this.setState({
       rank: record.Rank,
       isEdit: true
     })
   };
   componentDidMount() {
     this.getListData();
   }
   getListData = () => {
     http.get(`/banner/list?&page=${this.state.currentPage}`).then(res => {
       this.setState({
         dataList: res.data.items || [],
         currentPage: res.data.current_page,
         total:res.data.total
       });
     })
   }
   onChangePage = (page) => {
     this.setState({
       currentPage: page,
     }, () => {
       this.getListData()
     });
   }
  render() {
    const {dataList, total, columns} = this.state;
    return (
      <div className="admin-user-container  main-container home-banner">
        {dataList.length<6&& <div className={'btn'}>
          <Button className={'add-btn'} onClick={() => this.addBanner()}>
            添加banner
          </Button>
        </div>}
        {
          dataList.length > 0 ?
            <div className="list-box">
              <Table rowKey={'ID'} columns={columns} dataSource={dataList} pagination={false}/>
              <Pagination
                className={'page-style'}
                onChange={this.onChangePage.bind(this)}
                defaultCurrent={1}
                total={total}
                pageSize={10}
                showSizeChanger={false}
                current={this.state.currentPage}
                showTotal={total => `共${total}条`}
              />
            </div> :
            <div className="list-box">
              <NoData/>
            </div>
        }
      </div>
    )
  }
}
export default withRouter(HomeBanner)
