import React from 'react';
import './index.scss'
import http from "../../lib/service/http";
import {localTime, setNull, getImgUrl} from "../../lib/help/helper";
import jigouLogo from "../../resource/images/public/jigou_logo.svg"

export default class AuthorIndex extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            info: {},
            user: {}
        }
    }

    getUserInfo = () => {
        http.get(`/front-user/detail/${this.props.match.params.id}`).then(res => {
            if (res) {
                this.setState({
                    info: res.info ? res.info : {},
                    user: res.user ? res.user : {},
                })
            }
        })
    }


    componentDidMount() {
        this.getUserInfo();
    }

    componentWillUnmount() {
    }

    render() {
        const {user, info} = this.state;
        return (
            <div className="account-info-container main-container">
                <div className="info-title-box">
                    <p className="title">账号信息</p>
                </div>
                <div className="info-content">
                    <div className="item-type-box">
                        <div className="item-box">
                            <p className="label-text">手机号</p>
                            <p className="desc">{user.Telephone}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">注册时间</p>
                            <p className="desc">{localTime(user.CreatedAt)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">企业账号</p>
                            <p className="desc">{user.UserName}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">邮箱</p>
                            <p className="desc">{setNull(user.Email)}</p>
                        </div>
                    </div>
                </div>
                <div className="info-title-box">
                    <p className="title">企业信息</p>
                </div>
                <div className="info-content">
                    <div className="item-type-box">
                        <div className="item-box">
                            <p className="label-text">企业名称</p>
                            <p className="desc">{setNull(info.Name)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">统一社会信用代码</p>
                            <p className="desc">{setNull(info.UnifiedSocialCreditCode)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">企业类型</p>
                            <p className="desc">{setNull(info.Type)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">法定代表人姓名</p>
                            <p className="desc">{setNull(info.LegalPersonName)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">法定代表人家庭住址</p>
                            <p className="desc">{setNull(info.LegalPersonAddress)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">法定代表人身份证号</p>
                            <p className="desc">{setNull(info.LegalPersonIDNumber)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">身份证有效期</p>
                            <p className="desc">{setNull(info.LegalPersonIDValidityStr)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">法定代表人身份证</p>
                            {
                                info.LegalPersonIDFrontFid ?
                                    <div className="img-box">
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LegalPersonIDFrontFid)} alt=""/>
                                        </div>
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LegalPersonIDBackFid)} alt=""/>
                                        </div>
                                    </div> :
                                    <p className="desc">暂无图片</p>
                            }
                        </div>
                        <div className="item-box">
                            <p className="label-text">营业执照</p>
                            {
                                info.BusinessLicenseFid ?
                                    <div className="img-box">
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.BusinessLicenseFid)} alt=""/>
                                        </div>
                                    </div> :
                                    <p className="desc"><img src={jigouLogo} alt=""/></p>
                            }
                        </div>
                        <div className="item-box">
                            <p className="label-text">营业执照核准日期</p>
                            <p className="desc">{localTime(info.BusinessLicenseApprovalDate).split(' ')[0]}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">企业Logo</p>
                            {
                                info.LogoFid ?
                                    <div className="img-box">
                                        <div className="img-bg">
                                            <img src={getImgUrl(info.LogoFid)} alt=""/>
                                        </div>
                                    </div> :
                                  <p className="desc"><img src={jigouLogo} alt=""/></p>
                            }
                        </div>
                        <div className="item-box">
                            <p className="label-text">企业地址</p>
                            <p className="desc">{setNull(info.Address)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">注册资本</p>
                            <p className="desc">{setNull(info.RegisteredCapital)}元整</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">成立日期</p>
                            <p className="desc">{localTime(info.FoundedDate).split(' ')[0]}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">营业期限</p>
                            <p className="desc">{setNull(info.BusinessLicenseValidityStr)}</p>
                        </div>
                        <div className="item-box">
                            <p className="label-text">经营范围</p>
                            <p className="desc">{setNull(info.BusinessScope)}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
