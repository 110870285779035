import React from 'react';
import {Button, Form, Input, message} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import E from "wangeditor";
import {regexps_news_title} from '../../lib/help/validates';
import {rules} from '../../lib/help/rules';
import {getImgPublicUrl} from '../../lib/help/helper'
import Img from "../../resource/images/public/img-default.jpg";
import UploadFile from '../../components/UploadFile'

const {add_news_title, add_news_source, upload_file} = rules

export default class NewsAdd extends React.Component {
    formRef = React.createRef();
    uploadRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            editor: {},
            fileUrlState: true,
            contentState: true,
            titleState: true,
            outputHTML: '',
            fileUrl: '',
            titleValue: '',
            type: 'publish',
            newsInfo: {},
            fileList: []
        }
    }

    componentDidMount() {
        this.setEditor();
    }

    componentWillUnmount() {
        this.setState = ()=>false;
    }
    setEditor() {
        let editor = new E("#editor-wrapper");
        editor.config.height = 350;
        editor.config.zIndex = 100
        editor.config.showLinkImg = false;
        editor.config.uploadImgMaxLength = 1;
        editor.config.customUploadImg = function (resultFiles, insertImgFn) {
            let tmpFile = resultFiles[0];
            var data = new FormData();
            data.append("file", tmpFile);
            http.post('/file/upload-public', data).then(res => {
                insertImgFn(getImgPublicUrl(res.data.fid))
            })
            // resultFiles 是 input 中选中的文件列表
            // insertImgFn 是获取图片 url 后，插入到编辑器的方法
            // 上传图片，返回结果，将图片插入到编辑器中
            // insertImgFn(imgUrl)
        }

        editor.config.menus = [
            'head',
            'bold',
            'fontSize',
            'fontName',
            'italic',
            'underline',
            // 'strikeThrough',
            'indent',
            'lineHeight',
            'foreColor',
            'backColor',
            'link',
            'list',
            'justify',
            // 'quote',
            // 'image',
            'table',
            // 'splitLine',
        ]
        editor.config.customAlert = function (s, t) {
            switch (t) {
                case 'success':
                    message.success(s)
                    break
                case 'info':
                    message.info(s)
                    break
                case 'warning':
                    message.warning(s)
                    break
                case 'error':
                    message.error(s)
                    break
                default:
                    message.info(s)
                    break
            }
        }
        editor.config.focus = false;
        editor.config.placeholder = '请输入政策内容';
        editor.config.onchange = (newHtml) => {
            this.setState({
                contentState: newHtml.length > 0,
            })
        }
        editor.create();
        //编辑
        let newsInfo = {};
        if (this.props.match.params.id) {
            this.httpDetail(res => {
                newsInfo = res.News;
                this.formRef.current.setFieldsValue({
                    title: newsInfo.Title,
                    source: newsInfo.Source,
                });
                editor.txt.html(newsInfo.Content);
                this.setState({
                    fileList: (res.AttachFile??[]).map(file => {
                        return {
                            originUrl: file.URL,
                            url: file.URL,
                            name: file.Name || ''
                        }
                    })
                })
            });
        }
        this.setState({
            editor: editor,
            newsInfo: newsInfo
        })
    }

    httpDetail(callback) {
        http.get('news/detail/' + this.props.match.params.id).then(res => {
            if (res) {
                callback(res);
            }
        })
    }

    getTitle(event) {
        this.setState({
            titleValue: event.target.value.trim(),
            titleState: regexps_news_title.test(event.target.value.trim())
        })
    }

    getFileInfo(fileInfo) {
        this.setState({
            fileUrl: fileInfo,
            fileUrlState: fileInfo
        })
    }


    onFinish = (values) => {
        if (!this.regexpsContent()) return false;
        const tmpEditor = this.state.editor.txt.html()
        let params = {
            ...values,
            content: tmpEditor,
            publish: this.state.type === 'publish'
        }
        const fileList = this.uploadRef.current.state.fileList;
        params.attach_files = fileList.map(file => {
            return {
                attach_file_name: file.name,
                attach_file_path: file.originUrl
            }
        });
        let url = '/news/create';
        if(this.props.match.params.id) {
            url = '/news/update';
            params.id = this.props.match.params.id - 0
        }
        http.post(url, params).then(() => {
            if (this.state.type === 'publish') {
                message.success('发布成功');
            } else {
                message.success('保存成功');
            }
            this.goBack()
        })
    }
    goBack() {
        this.formRef.current.resetFields();
        this.state.editor.txt.clear();
        sessionStorage.removeItem("editNews");
        this.props.history.push('/content/news');
    }
    saveContent(type) {
        this.setState({
            type: type
        }, ()=> {
            this.formRef.current.submit();
        })
    }
    regexpsContent() {
        if (!(this.state.editor.txt.html().length > 0)) {
            this.setState({
                contentState: false,
            })
        }
        return this.state.editor.txt.html().length > 0;
    }
    onFinishFailed = () => {
        this.regexpsContent()
    }

    render() {

        return (
            <div className="add-news-container debt-finance main-container admin-user-container">
                <div className="news-add-content">
                    <Form
                        name="basic"
                        className="form-content"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Form.Item
                            label="政策标题"
                            name="title"
                            rules={add_news_title}
                            className={'search-item not-extra'}
                        >
                            <Input placeholder={'请输入政策标题，最长不超过100字符'}/>
                        </Form.Item>
                        <Form.Item
                            label="来源"
                            name="source"
                            rules={add_news_source}
                            className={'search-item not-extra'}
                        >
                            <Input placeholder={'请输入来源，最长不超过50字符'}/>
                        </Form.Item>
                        <div className="add-news-item">
                            <p className="add-news-label label-top">
                                政策内容
                            </p>
                            <div className="editor-wrapper" id={'editor-wrapper'}>
                            </div>
                            <p className={this.state.contentState ? 'dialog-error-text' : 'dialog-error-text show'}>请输入政策内容</p>
                        </div>

                        <Form.Item
                            label="附件"
                            name="attach_files"
                            className={'upload-row'}
                            // rules={upload_file}
                            extra={<div className={'extra'}><p>附件支持word、pdf、excel、图片等文件，单个附件大小不超过5M</p></div>}
                            validateFirst={true}
                        >
                            <UploadFile ref={this.uploadRef} fileList={this.state.fileList} key={JSON.stringify(this.state.fileList)}/>
                        </Form.Item>

                        <Form.Item className="submit-btn-box">
                            <Button className={'submit-btn btn-yellow'} onClick={() => this.saveContent('publish')}>
                                确认发布
                            </Button>
                            <Button className={'submit-btn'} onClick={() => this.saveContent('save')}>
                                内容保存
                            </Button>
                            <Button className={'submit-btn'} onClick={() => this.goBack()}>
                                取消发布
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}