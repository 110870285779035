import React from 'react';
import { Upload, Button, message, Modal } from 'antd';
import './index.scss'
import http from "../../lib/service/http";
import {getImgUrl, genUuid, getFileUrl} from '../../lib/help/helper'
import { UploadOutlined, ExclamationCircleOutlined, DeleteOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import download from 'downloadjs';

const { confirm } = Modal;


export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OSSData: {},
            successData: {},
            uploadStatus: '',
            fileList: props.fileList || [],
            fileSizeStatus: false,
        }
    }
    async componentDidMount() {
        await this.init();
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.fileList !== this.props.fileList) {
    //         this.setState({
    //             fileList: nextProps.fileList
    //         })
    //     }
    // }

    init = async () => {
        let OSSData = (await this.mockGetOSSData());
        OSSData.accessId = OSSData.accessid;
        delete OSSData.accessid;
        this.setState({
            OSSData: OSSData,
        });
    };
    mockGetOSSData = async () => {
        return await http.get('/upload/sign').then(res=>{
            return res
        })
    };

    getExtraData = file => {
        const { OSSData } = this.state;

        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessId,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };
    beforeUpload = async (file) => {
        console.log('beforeUpload', file)
        const { OSSData } = this.state;
        // const { onChange } = this.props;
        const expire = OSSData.expire * 1000;
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = genUuid() + suffix;
        file.url = OSSData.dir + '/' + filename;

        return new Promise(async (resolve, reject) => {
            if (expire < Date.now()) {
                await this.init();
            }
            // 最多允许 10 个文件
            if (this.state.fileList.length >= 10) {
                message.error('最多可添加10个附件!');
                return reject(false);
            }
            // 文件大小不允许超过 5M
            if(file.size > 5 * 1024 * 1024) {
                message.error('文件大小不能超过5MB!');
                return reject(false);
            }

            // 文件类型判断：windows 里所有类型的文件都可以上传
            const fileType = file.type.indexOf('image') !== -1 ? 'image' : file.name.slice(file.name.lastIndexOf('.') + 1);
            // console.log('fileType', fileType)
            const array = ['image', 'pdf', 'doc', 'docx', 'xlsx', 'xls'];
            if (array.indexOf(fileType) === -1) {
              message.error('文件格式不符合要求!');
              return reject(false);
            }
            resolve(file);
        });
    };
    onChange = ({ file, fileList }) => {
        // console.log('onChange', fileList)
        // const { OSSData } = this.state;
        this.setState({
            fileList: fileList.filter(file => {
                // 已经存在的没有 lastModified 属性值
                // 没有 status 的是被 beforeUpload 屏蔽的
                return file.status || !file.lastModified;
            }).map(file => {
                file.originUrl = file.url;
                return file;
            })
        });
    }
    remove = file => {
        const _this = this;
        confirm({
            className: 'confirm-dialog',
            title: '删除后附件不可找回，确定是否删除?',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                let {fileList} = _this.state;
                for(let i = fileList.length - 1; i >=0; i--) {
                    if (fileList[i].url === file.url) {
                        fileList.splice(i, 1)
                    }
                }
                _this.setState({
                    fileList
                });
            },
            onCancel() {
            },
        });
    };

    getUrl = (url) => {
        if (url.indexOf('http') !== -1) {
            return url;
        } else {
            return getFileUrl(url);
        }
    };

    preview = (file) => {
      let fileType = file.url.slice(file.url.lastIndexOf('.') + 1);
      const typeArr = ['doc', 'docx', 'xlsx', 'xls', 'csv'];
      if (typeArr.indexOf(fileType) !== -1) {
        this.download(file);
      } else {
        window.open(this.getUrl(file.url));
      }
    };
    download = (file) => {
      let x=new XMLHttpRequest();
      x.open( "GET", this.getUrl(file.url) , true);
      x.responseType="blob";
      x.onload= function(e){download(e.target.response, file.name);};
      x.send();
    };

    render() {
        const props = {
            name: 'file',
            // accept: 'image/*,.doc,.docx,.pdf,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileList: this.state.fileList,
            action: this.state.OSSData.host,
            method: 'post',
            onChange: this.onChange,
            data: this.getExtraData,
            beforeUpload: this.beforeUpload,
            multiple: false,
            progress: {
                strokeWidth: 2,
                strokeColor: '#EA4335',
                // showInfo: true
            },
            showUploadList: false,
            maxCount: 10
        };

        return (
          <div className="upload-file-container">
              <Upload {...props}>
                  {this.state.fileList.length < 10 &&
                      <Button className={'submit-btn'}  icon={<UploadOutlined />}>上传</Button>
                  }
              </Upload>
              <ul className={"file-ul"}>
                  {this.state.fileList.map((file, index) =>
                      <li key={file.name + index} className={"file-row"}>
                          <a className={"file-name"} title={file.name} onClick={() => this.preview(file)}>{file.name}</a>
                          <p>
                              <VerticalAlignBottomOutlined className={"file-icon"} onClick={() => this.download(file)}/>
                              <DeleteOutlined className={"file-icon"} onClick={() => this.remove(file)}/>
                          </p>
                      </li>
                  )}
              </ul>
          </div>
        )
    }
}
