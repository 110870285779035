import React from 'react';
import ProductRecord from '../../common/Record/productRecord'
import http from "../../lib/service/http";
export default class Record extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordData: {},
      detail: {},
      productObject: {},
    }
  }
  getListData(params) {
    http.get(`/product/financial-detail/${params}`).then(res => {
      this.setState({
        recordData: res.product_timeline,
        detail:res.detail
      });
    })
  };
  componentWillMount () {
    const id = this.props.props.match.params.id;
    this.getListData(id);
  }

  render() {
    return (
      <div className="admin-user-container debt-finance main-container">
        <ProductRecord recordData={this.state.recordData} detail={this.state.detail}/>
      </div>
    )
  }
}
