import Cookie from "js-cookie";
import requestSign from "zigg-request-sign"

export function localTime(str) {
    if (!str) return '--';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let hour = (date.getHours() + '').padStart(2, '0');
    let minute = (date.getMinutes() + '').padStart(2, '0');
    let second = (date.getSeconds() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day + " " +  hour + ":" + minute + ":" + second;
}
export function localDate(str) {
    if (!str) return '--';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day;
}
export function DateToLocalTime(date) {
    if(!date)return'--';
    let str = date.toISOString();
    let dateStr = str.split('T')[0];
    let timeStr = date.toTimeString().split(' ')[0];
    return dateStr + ' ' + timeStr;
}
function getImg(url,token){
    let params ={};
    if(token) {
        params =requestSign(process.env.REACT_APP_SECRET, url,{token: token });
    } else {
        params =requestSign(process.env.REACT_APP_SECRET, url);
    }
    let array = [];
    for(let i in params){
        let data  = i+'='+params[i];
        array.push(''+data+'');
    }
    return array.join('&')
}
//
function getFile(url, obj){
    let params ={};
    if(obj && Object.keys(obj).length !== 0) {
        params =requestSign(process.env.REACT_APP_SECRET, url, obj);
    } else {
        params =requestSign(process.env.REACT_APP_SECRET, url);
    }
    let array = [];
    for(let i in params){
        let data  = i+'='+params[i];
        array.push(''+data+'');
    }
    return array.join('&')
}
export function getImgUrl(id) {
    return process.env.REACT_APP_ADMIN_API+'/v1/private-file/'+id+'?token='+Cookie.get('token')+'&'+getImg('/v1/private-file',Cookie.get('token'));
}
export function getImgPublicUrl(id) {
    return process.env.REACT_APP_ADMIN_API+'/v1/public-file/'+id+'?'+getImg('/v1/public-file');
}
export function getFileUrl(path) {
    const token = Cookie.get('token');
    return process.env.REACT_APP_ADMIN_API+'/v1/private-file-oss?' + 'token='+token+'&'+getFile('/v1/private-file-oss',{token, path});
}
export function genUuid() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
}

export function setHidePhone(number) {
    if(!number)return '';
    return number.substr(0, 3) + '****' + number.substr(7, 4)
}
export function getCurrentDate() {
    let num_arr = ['零', '一','二','三','四','五','六','天',]
    var date = new Date()
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var week_day = date.getDay();
    return year + "年" + month + "月" + day+ "日 星期" +num_arr[week_day];
}
export function setNull(value) {
    return value ? value : '- -'
}
