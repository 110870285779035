import './App.css';
import './resource/styles/public.scss'
import './resource/styles/loading.scss'
import {Link} from 'react-router-dom'

import {HashRouter as Router, Route, Switch} from "react-router-dom";
import React from 'react';
import {withRouter} from "react-router-dom";
import Aside from "./common/Aside";
import IndexHeader from './common/Header'
import {Layout, Breadcrumb} from 'antd';
import Index from './pages/Index'
import AdminUser from './pages/AdminUser'
import Login from './pages/Login'
import FooterDom from './common/Footer'
import ResetPassword from './pages/ResetPassword'
import RetrievePassword from './pages/RetrievePassword'
import InvitationCodeList from './pages/InvitationCodeList'
import ProjectFinancingList from './pages/ProductFinancingList'
import ProjectFinancingInfo from './pages/ProductFinancingInfo'
import ProjectCompanyList from './pages/ProductCompanyList'
import ProjectCompanyInfo from './pages/ProductCompanyInfo'
import UserCompanyList from './pages/UserCompanyList'
import UserFinancialList from './pages/UserFinancialList'
import UserCompanyInfo from './pages/UserCompanyInfo'
import UserFinancialInfo from './pages/UserFinancialInfo'
import ContentNewsList from "./pages/ContentNewsList/index"
import ContentNewsAdd from "./pages/ContentNewAdd/index"
import OrderFinancingList from './pages/OrderFinancingList'
import OrderFinancingInfo from './pages/OrderFinancingInfo'
import OrderCompanyList from './pages/OrderCompanyList'
import OrderCompanyInfo from './pages/OrderCompanyInfo'
import OperationLog from './pages/OperationLog'
import HomeBanner from './pages/HomeBanner'
import AddBanner from './pages/HomeBanner/addBanner'
import FinancingRequirement from './pages/FinancingRequirement'
const breadcrumbNameMap = {
    '/index': '首页',
    '/system': '系统管理',
    '/system/invite-code': '邀请码管理',
    '/system/user-list': '管理员管理',
    '/user': '用户管理',
    '/user/company-list': '企业列表',
    '/user/company-list/id': '企业详情',
    '/user/financial-list': '机构列表',
    '/user/financial-list/id': '机构详情',
    '/product': '产品管理',
    '/product/financing-list': '贷款融资列表',
    '/product/financing-list/id': '贷款融资详情',
    '/product/company-list': '企业服务列表',
    '/product/company-list/id': '企业服务详情',
    '/order': '订单管理',
    '/order/financing-list': '贷款融资列表',
    '/order/financing-list/id': '贷款融资详情',
    '/order/company-list': '企业服务列表',
    '/order/company-list/id': '企业服务详情',
    '/system/operation-log': '操作日志',
    '/content': '内容管理',
    '/content/banner': '首页轮播banner',
    '/content/banner/add': '添加banner',
    '/content/news': '政策资讯',
    '/content/news/add': '添加政策',
    '/content/news/edit': '编辑政策',
    '/requirementManage': '融资需求管理'
};
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            currentRoute: this.props.location,
        }
    }

    componentDidMount() {
        this.props.history.listen(route => {
            this.setState({
                currentRoute: route
            })
        })
    }
    componentWillUnmount () {
        this.setState = ()=>false;
    }

    setAside() {
        let route = this.state.currentRoute;
        return route.hash === '#/' ||
            route.hash === '#/reset-password' ||
            route.hash === '#/retrieve-password'
    }


    render() {
        const {Header, Content, Footer} = Layout;
        const {location} = this.props;
        const pathSnippets = location.hash.substr(1,).split('/').filter(i => i);
        const extraBreadcrumbItems = pathSnippets.map((_, index) => {
                const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
                if (url.split('/').length === 3 && index === 1) {
                    return (
                        <Breadcrumb.Item key={url}>
                            <Link to={url}>
                                {breadcrumbNameMap[url]}
                            </Link>
                        </Breadcrumb.Item>
                    );
                } else {
                    let text = url
                    if (!breadcrumbNameMap[url]) {
                        text = '/'+pathSnippets.slice(0, index).concat(['id']).join('/')
                    }
                    return (
                        <Breadcrumb.Item key={url}>
                            {breadcrumbNameMap[text]}
                        </Breadcrumb.Item>
                    );
                }
            }
        );
        return (
            <Router>
                <Layout className="content">
                    {
                        !this.setAside() &&
                        <Aside/>
                    }
                    <Layout>
                        <Header>
                            <IndexHeader/>
                        </Header>
                        <Content>
                            {
                                !this.setAside() &&
                                <Breadcrumb separator=">" className="bread-crumb">{extraBreadcrumbItems}</Breadcrumb>
                            }
                            <Switch>
                                <Route exact path="/" component={Login}/>
                                <Route exact path="/index" component={Index}/>
                                <Route exact path="/requirementManage" component={FinancingRequirement}/>
                                <Route exact path="/reset-password" component={ResetPassword}/>
                                <Route exact path="/retrieve-password" component={RetrievePassword}/>
                                <Route exact path="/system/user-list" component={AdminUser}/>
                                <Route exact path="/system/operation-log" component={OperationLog}/>
                                <Route exact path="/system/invite-code" component={InvitationCodeList}/>
                                <Route exact path="/product/financing-list" component={ProjectFinancingList}/>
                                <Route exact path="/product/financing-list/:id" component={ProjectFinancingInfo}/>
                                <Route exact path="/product/company-list" component={ProjectCompanyList}/>
                                <Route exact path="/product/company-list/:id" component={ProjectCompanyInfo}/>
                                <Route exact path="/order/financing-list" component={OrderFinancingList}/>
                                <Route exact path="/order/financing-list/:id" component={OrderFinancingInfo}/>
                                <Route exact path="/order/company-list" component={OrderCompanyList}/>
                                <Route exact path="/order/company-list/:id" component={OrderCompanyInfo}/>
                                <Route exact path="/user/company-list" component={UserCompanyList}/>
                                <Route exact path="/user/company-list/:id" component={UserCompanyInfo}/>
                                <Route exact path="/user/financial-list" component={UserFinancialList}/>
                                <Route exact path="/user/financial-list/:id" component={UserFinancialInfo}/>
                                <Route exact path="/content/banner" component={HomeBanner}/>
                                <Route exact path="/content/banner/add" component={AddBanner}/>
                                <Route exact path="/content/news" component={ContentNewsList}/>
                                <Route exact path="/content/news/add" component={ContentNewsAdd}/>
                                <Route exact path="/content/news/edit/:id" component={ContentNewsAdd}/>

                            </Switch>
                        </Content>
                        {
                            this.setAside() &&
                            <Footer>
                                <FooterDom/>
                            </Footer>
                        }
                    </Layout>
                </Layout>
            </Router>
        );
    }
}


export default withRouter(App);
