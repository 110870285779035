import React from 'react';
import './index.scss'
import {Col, Row, Tabs} from "antd";
import {Link} from 'react-router-dom'
import * as echarts from 'echarts';
import DebtList from "../ProductFinancingList/DebtList";
import CompanyList from "../ProductCompanyList/DebtList"
import _ from 'lodash'
import http from "../../lib/service/http";
import noDataImg from "../../resource/images/public/img-no-data.svg";
import 'zrender/lib/svg/svg';
const {TabPane} = Tabs;
export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            histogramOption: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '0px',
                    right: '16px',
                    bottom: '0px',
                    top: '8px',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(0,0,0,.45)',
                                fontSize: '12px'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(0,0,0,.45)',
                                fontSize: '12px'
                            }
                        }
                    },
                ],
                series: [
                    {
                        name: '新增',
                        type: 'bar',
                        barWidth: '60%',
                        data: []
                    }
                ]
            },
            doubleHistogramOption: {

                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['贷款融资', '企业服务'],
                    itemHeight: 4,
                    itemWidth: 12,
                    textStyle: {
                        color: 'rgba(0,0,0,.45)',
                        fontSize: '12px'
                    },
                    x: 'left',
                    y: 'top',
                    selectedMode: false,
                },
                grid: {
                    left: '0px',
                    right: '12px',
                    bottom: '0px',
                    top: '42px',
                    containLabel: true
                },
                toolbox: {
                    show: true,
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(0,0,0,.45)',
                                fontSize: '12px'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(0,0,0,.45)',
                                fontSize: '12px'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '贷款融资',
                        type: 'bar',
                        data: [],

                    },
                    {
                        name: '企业服务',
                        type: 'bar',
                        data: [],

                    }
                ]
            },
            lineOption: {
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '0px',
                    right: '12px',
                    bottom: '0px',
                    top: '42px',
                    containLabel: true
                },
                legend: {
                    data: ['贷款融资(单位:万元)', '企业服务(单位:万元)'],
                    x: 'left',
                    y: 'top',
                    textStyle: {
                        color: 'rgba(0,0,0,.45)',
                        fontSize: '12px'
                    },
                    selectedMode: false,
                },
                xAxis: {
                    type: 'category',
                    nameLocation: 'middle',
                    data: [],
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,.45)',
                            fontSize: '12px'
                        }
                    }
                },
                yAxis: {
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,.45)',
                            fontSize: '12px'
                        }
                    }
                },
                series: [
                    {
                        name: '贷款融资(单位:万元)',
                        type: 'line',
                        showSymbol: false,
                        data: [],
                    },
                    {
                        name: '企业服务(单位:万元)',
                        type: 'line',
                        showSymbol: false,
                        data: [],
                    }
                ]
            },
            leftHistogramOption: {
                title: {},
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '0px',
                    right: '24px',
                    bottom: '0px',
                    top: '0px',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        interval: 3,
                        // 注释formatter保留 在init时候设置 方便后续迭代理解
                        // formatter: function (params) {
                        //     return params+"%"
                        // },
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,.45)',
                            fontSize: '12px'
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        // 注释formatter保留 在init时候设置 方便后续迭代理解
                        // formatter: function (params) {
                        //     if(params.length > 0) {
                        //         return params.slice(0, 10) + '\n' + params.slice(10,)
                        //     }
                        //     return params
                        // },
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,0,0,.45)',
                            fontSize: '12px'
                        }
                    }
                },
                series: [
                    {
                        type: 'bar',
                        data: [],
                        barWidth: '22px',
                        itemStyle: {
                            normal: {}
                        },
                        label: {
                            normal: {}
                        }
                    },
                ]
            },
            activeKey: '0',
            financingTotal: '-1',
            companyTotal: '-1',

            userCompany: '',
            userAgency: '',
            projectNumber: '',
            companyNumber: '',
            applicationAmount: '',
            reachAmount: '',
            leftProduct: '',
            leftSuccess: '',
            userCompanyData: {},
            userAgencyData: {},
            projectNumberData: {},
            companyNumberData: {},
            applicationAmountData: {},
            reachAmountData: {},
            leftProductData: {
                ChartData: {
                    ChartX: [],
                }
            },
            leftSuccessData: {
                ChartData: {
                    ChartX: [],
                }
            },
        }
    }

    initEcharts(option, id) {
        var chartDom = document.getElementById(id);
        var myChart = echarts.init(chartDom, null, {renderer: 'svg'});
        option && myChart.setOption(option);
        return myChart
    }

    componentDidMount() {
        this.getCompanyAccount();
        this.getAgencyAccount();
        this.getProjectAccount();
        this.getCompanyNumber();
        this.getApplicationAmount();
        this.getReachAmount();
        this.getLeftProduct();
        this.getLeftSuccess();
        window.addEventListener("resize", _.debounce(() => {
            this.state.userCompany && this.state.userCompany.resize();
            this.state.userAgency && this.state.userAgency.resize();
            this.state.projectNumber && this.state.projectNumber.resize();
            this.state.companyNumber && this.state.companyNumber.resize();
            this.state.applicationAmount && this.state.applicationAmount.resize();
            this.state.reachAmount && this.state.reachAmount.resize();
            this.state.leftProduct && this.state.leftProduct.resize();
            this.state.leftSuccess && this.state.leftSuccess.resize();
        }))
    }

    componentWillUnmount() {
        // this.setState = ()=>false;
        // window.removeEventListener('resize');
    }

    setColor(params) {
        //如果颜色太少的话，后面颜色不会自动循环
        var colorList = ['#e6cb73', '#e6896b', '#eeb46f', '#96b682', '#82b08a'];
        return colorList[params.dataIndex]
    }

    setXContent(params) {
        return params + "%"
    }

    setTooltipContent(params) {
        return `<div class="tooltip-content"><p class="name">${params[0].name}</p><div class="number-text"><span style="background-color: ${params[0].color}"></span><p>${Number(params[0].value) + '%'}</p></divcla></div>`
    }

    setYContent(params) {
        if (params.length > 0) {
            return params.slice(0, 10) + '\n' + params.slice(10,)
        }
        return params
    }

    setSuccessY(value) {
        if (value.length > 10 && value.length <= 20) {
            return value.slice(0, 10) + '\n' + value.slice(10,)
        }
        if (value.length > 20) {
            return (value.slice(0, 10) + '\n' + value.slice(10, 19) + "...")
        }
        return value
    }

    changeTab = (key) => {
        this.setState({
            activeKey: key
        })
    };
    setNullStatus = (total, type) => {
        this.setState({
            [type]: total
        })
    }

    getCompanyAccount() {
        http.get('/overview/enterprise').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.histogramOption))
            option.xAxis[0].data = res.ChartData.ChartX.reverse();
            option.series[0].data = res.ChartData.ChartY.reverse();
            this.setState({
                userCompany: this.initEcharts(option, 'user-company'),
                userCompanyData: res,
            })

        })
    }

    getAgencyAccount() {
        http.get('/overview/financial').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.histogramOption))
            option.xAxis[0].data = res.ChartData.ChartX.reverse();
            option.series[0].data = res.ChartData.ChartY.reverse();
            this.setState({
                userAgency: this.initEcharts(option, 'user-agency'),
                userAgencyData: res,
            })

        })
    }

    getProjectAccount() {
        http.get('/overview/product').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.doubleHistogramOption))
            option.xAxis[0].data = res.FinancialChartData.ChartX.reverse();
            option.series[0].data = res.FinancialChartData.ChartY.reverse();
            option.series[1].data = res.ServiceChartData.ChartY.reverse();
            this.setState({
                projectNumber: this.initEcharts(option, 'project-number'),
                projectNumberData: res,
            })

        })
    }

    getCompanyNumber() {
        http.get('/overview/apply').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.lineOption))
            option.legend.data = ['贷款融资', '企业服务'];
            option.series[0].name = '贷款融资';
            option.series[1].name = '企业服务';
            option.xAxis.data = res.FinancialChartData.ChartX.reverse();
            option.series[0].data = res.FinancialChartData.ChartY.reverse();
            option.series[1].data = res.ServiceChartData.ChartY.reverse();
            this.setState({
                companyNumber: this.initEcharts(option, 'company-number'),
                companyNumberData: res,
            })

        })
    }

    getApplicationAmount() {
        http.get('/overview/apply-amount').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.lineOption))
            option.xAxis.data = res.FinancialChartData.ChartX.reverse();
            option.series[0].data = res.FinancialChartData.ChartY.reverse();
            option.series[1].data = res.ServiceChartData.ChartY.reverse();
            this.setState({
                applicationAmount: this.initEcharts(option, 'application-amount'),
                applicationAmountData: res,
            })

        })
    }

    getReachAmount() {
        http.get('/overview/match-amount').then(res => {
            let option = JSON.parse(JSON.stringify(this.state.lineOption))
            option.xAxis.data = res.FinancialChartData.ChartX.reverse();
            option.series[0].data = res.FinancialChartData.ChartY.reverse();
            option.series[1].data = res.ServiceChartData.ChartY.reverse();
            this.setState({
                reachAmount: this.initEcharts(option, 'reach-amount'),
                reachAmountData: res,
            })

        })
    }

    getLeftProduct() {
        http.get('/overview/apply-rank').then(res => {
            if (res.ChartData.ChartX.length > 0) {
                let option = JSON.parse(JSON.stringify(this.state.leftHistogramOption));
                option.yAxis.data = res.ChartData.ChartX.reverse();
                option.series[0].data = res.ChartData.ChartY.reverse();
                option.series[0].itemStyle.normal.color = this.setColor;
                option.yAxis.axisLabel.formatter = this.setYContent;
                this.setState({
                    leftProduct: this.initEcharts(option, 'left-product'),
                    leftProductData: res,
                })
            } else {
                this.setState({
                    leftProductData: res,
                })
            }
        })
    }

    getLeftSuccess() {
        http.get('/overview/match-rank').then(res => {
            if (res.ChartData.ChartX.length > 0) {
                let option = JSON.parse(JSON.stringify(this.state.leftHistogramOption))
                option.yAxis.data = res.ChartData.ChartX.reverse();
                option.series[0].data = res.ChartData.ChartY.reverse();
                option.series[0].itemStyle.normal.color = this.setColor;
                option.tooltip.formatter = this.setTooltipContent;
                option.xAxis.axisLabel.formatter = this.setXContent;
                option.yAxis.axisLabel.formatter = this.setSuccessY;
                this.setState({
                    leftSuccess: this.initEcharts(option, 'left-success'),
                    leftSuccessData: res,
                })
            } else {
                this.setState({
                    leftSuccessData: res,
                })
            }
        })
    }

    render() {
        const {
            userCompanyData,
            userAgencyData,
            projectNumberData,
            companyNumberData,
            applicationAmountData,
            reachAmountData,
            leftProductData,
            leftSuccessData,
        } = this.state;
        return (
            <div className="index-container main-container">
                <div className="index-content">
                    <Row gutter={24} className={'table-row'}>
                        <Col xl={6} lg={12} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">企业数量</p>
                                    <Link to={'/user/company-list'}>详情</Link>
                                </div>
                                <p className="all-number item-title">
                                    {userCompanyData.EnterpriseTotal}个
                                </p>
                                <div id="user-company" className="chart-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{userCompanyData.WeekNewTotal}</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span>{userCompanyData.MonthNewTotal}</span>
                                </p>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">机构数量</p>
                                    <Link to={'/user/financial-list'}>详情</Link>
                                </div>
                                <p className="all-number item-title">
                                    {userAgencyData.FinancialTotal}个
                                </p>
                                <div id="user-agency" className="chart-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{userAgencyData.WeekNewTotal}</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span>{userAgencyData.MonthNewTotal}</span>
                                </p>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">金融产品数量</p>
                                    <Link to={'/product/financing-list'}>详情</Link>
                                </div>
                                <p className="all-number item-title">
                                    {projectNumberData.ProductTotal}个
                                </p>
                                <div id="project-number" className="chart-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{projectNumberData.WeekNewTotal}</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span>{projectNumberData.MonthNewTotal}</span>
                                </p>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">企业申请次数</p>
                                    <Link to={'/order/financing-list'}>详情</Link>
                                </div>
                                <p className="all-number item-title">
                                    {companyNumberData.ApplyTotal}个
                                </p>
                                <div id="company-number" className="chart-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{companyNumberData.WeekNewTotal}</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span> {companyNumberData.MonthNewTotal}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24} className={'table-row'}>
                        <Col xl={12} lg={24} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">申请金额</p>
                                </div>
                                <div className="all-number item-title">
                                    共 {applicationAmountData.ApplyAmountSum}万元
                                    <p className="info-text line-number-text">
                                        债融 {applicationAmountData.ApplyAmountFinancialSum}万元 + 企服 {applicationAmountData.ApplyAmountServiceSum}万元
                                    </p>
                                </div>
                                <div id="application-amount" className="chart-content amount-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{applicationAmountData.WeekNewTotal}万元</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span>{applicationAmountData.MonthNewTotal}万元</span>
                                </p>
                            </div>
                        </Col>
                        <Col xl={12} lg={24} xs={12} className={'table-item'}>
                            <div className="item-content">
                                <div className="title-box">
                                    <p className="item-title">达成融资额</p>
                                </div>
                                <div className="all-number item-title">
                                    共 {reachAmountData.MatchAmountSum}万元
                                    <p className="info-text line-number-text">
                                        债融 {reachAmountData.MatchAmountFinancialSum}万元 + 企服 {reachAmountData.MatchAmountServiceSum}万元
                                    </p>
                                </div>
                                <div id="reach-amount" className="chart-content amount-content">
                                </div>
                                <p className="week-add">
                                    <span className="line-number-text">本周新增:</span>
                                    <span>{reachAmountData.WeekNewTotal}万元</span>
                                </p>
                                <p className="week-add">
                                    <span className="line-number-text">本月新增:</span>
                                    <span>{reachAmountData.MonthNewTotal}万元</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="project-list-content debt-finance">
                        <div className="title-box">
                            <p className="title">待平台审核产品</p>
                            {

                            }
                            <div className="title-right debt-content">
                                <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
                                    <TabPane tab={
                                        <p className="tab-item tab-item-1">贷款融资</p>
                                    } key="0">
                                    </TabPane>
                                    <TabPane tab={
                                        <p className="tab-item tab-item-2">企业服务</p>
                                    } key="1">
                                    </TabPane>
                                </Tabs>
                                {
                                    this.state.activeKey === '0' ?
                                        <Link to={'/product/financing-list'} className="link-text">详情</Link> :
                                        <Link to={'/product/company-list'} className="link-text">详情</Link>

                                }
                            </div>
                        </div>
                        <div className="pending-product">
                            {this.state.activeKey === '0' &&
                            <DebtList activeKey={'0'} type={'index'} setNullStatus={this.setNullStatus}/>}
                            {this.state.activeKey === '1' &&
                            <CompanyList activeKey={'0'} type={'index'} setNullStatus={this.setNullStatus}/>}
                        </div>
                    </div>
                    <div className="left-histogram-content">
                        <Row gutter={24} className={'table-row'}>
                            <Col xl={12} lg={24} xs={24} className={'table-item'}>
                                <div className="item-content">
                                    <div className="title-box">
                                        <p className="item-title">产品申请数量排行榜</p>
                                    </div>
                                    <div id="left-product" className="chart-content">
                                    </div>
                                    {
                                        leftProductData.ChartData.ChartX.length === 0 &&
                                        <img src={noDataImg} alt=""/>
                                    }
                                </div>
                            </Col>
                            <Col xl={12} lg={24} xs={24} className={'table-item'}>
                                <div className="item-content">
                                    <div className="title-box">
                                        <p className="item-title">各机构撮合成功率排行榜</p>
                                    </div>
                                    <div id="left-success" className="chart-content">
                                    </div>
                                    {
                                        leftSuccessData.ChartData.ChartX.length === 0 &&
                                        <img src={noDataImg} alt=""/>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
