import React from 'react';
import {Tabs} from "antd";
import List from "./List";

const {TabPane} = Tabs;


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '0'
    }
  }

  changeTab = (key) => {
    this.setState({
      activeKey: key
    })
  };

  render() {
    return (
      <div className="debt-finance main-container admin-user-container">
        <div className="debt-content admin-user-content">
          <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
            <TabPane tab={
              <p className="tab-item tab-item-1">全部</p>
            } key="0">
              {this.state.activeKey === '0' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合成功</p>
            } key="1">
              {this.state.activeKey === '1' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">待分配</p>
            } key="2">
              {this.state.activeKey === '2' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">待撮合</p>
            } key="3">
              {this.state.activeKey === '3' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">撮合失败</p>
            } key="4">
              {this.state.activeKey === '4' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已关闭</p>
            } key="5">
              {this.state.activeKey === '5' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
            <TabPane tab={
              <p className="tab-item tab-item-2">已失效</p>
            } key="6">
              {this.state.activeKey === '6' ? <List activeKey={this.state.activeKey}/> : <div/>}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}
