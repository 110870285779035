import React from 'react';
import {Tabs} from "antd";
import List from "./List";

const {TabPane} = Tabs;


export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '0'
        }
    }

    changeTab = (key) => {
        this.setState({
            activeKey: key
        })
    };

    render() {
        return (
            <div className="debt-finance main-container admin-user-container news-list-container">
                <div className="debt-content admin-user-content">
                    <Tabs defaultActiveKey="0" className={'tab-box'} onChange={this.changeTab.bind(this)}>
                        <TabPane tab={
                            <p className="tab-item tab-item-1">已发布</p>
                        } key="0">
                            {this.state.activeKey === '0' && <List activeKey={this.state.activeKey}/>}
                        </TabPane>
                        <TabPane tab={
                            <p className="tab-item tab-item-2">草稿箱</p>
                        } key="1">
                            {this.state.activeKey === '1' && <List activeKey={this.state.activeKey}/>}
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
