import React from 'react';
import {message, Modal, Menu, Dropdown, Form, Input, Steps, Button,} from 'antd';
import {Link, withRouter} from "react-router-dom";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getCurrentDate, setHidePhone } from "../../lib/help/helper";
import './index.scss'
import http from "../../lib/service/http";
import Cookie from "js-cookie";
import {DownOutlined} from '@ant-design/icons'
import {rules} from "../../lib/help/rules";
import userAvatar from '../../resource/images/public/user-avatar.svg'
import imgSuccess from '../../resource/images/public/img-success-tip.svg'
import {getUserInfo} from "../../lib/help/public"


const {password, phone, phone_code, not_null} = rules;

const {Step} = Steps;
let stopVal = '';

class Header extends React.Component {
    formRef = React.createRef();
    formPhoneRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            currentRoute: this.props.location,
            isConfirmVisible: false,
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
            cutDownTime: 60,
            isSend: false,
            userInfo: {
                user: {},
                info: {},
            },
            isModalVisible: false,
            isPhoneModalVisible: false,
            current: 0,
            steps: [
                {
                    title: '验证手机号码',
                },
                {
                    title: '更换手机号码',
                },
                {
                    title: '完成',
                },
            ],

        }
    }

    componentDidMount() {
        this.getUserInfo()
        this.props.history.listen(route => {
            this.setState({
                currentRoute: route
            })
        })
    }
    componentWillUnmount () {
        this.setState = ()=>false;
    }

    getUserInfo() {
        let tmpInfo = sessionStorage.getItem('adminUserInfo');
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            this.setState({
                userInfo: tmpInfo
            })
        }
    }
    logout = () => {
        http.post('/user/logout').then(res => {
            sessionStorage.clear();
            Cookie.remove('token');
            message.success('登出成功');
            this.props.history.replace('/');
            this.handleCancel()
        })
    }
    handleOk = () => {
        this.logout();
    }
    handleCancel = () => {
        this.setState({
            isConfirmVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isConfirmVisible: true
        })
    }
    handleCheckPwd = (_, value, callback) => {
        let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
        let oldPwd = this.formRef.current.getFieldValue('old_password');
        if(oldPwd && value && oldPwd === value) {
            callback(new Error('不能与当前密码一致'))
        }else if (cfmPwd && value && cfmPwd !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if(!this.formRef.current.isFieldValidating(['confirm_password']) && cfmPwd) {
                this.formRef.current.validateFields(['confirm_password']);
            }
            callback()
        }
    }

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if(!this.formRef.current.isFieldValidating(['password']) && loginPass) {
                this.formRef.current.validateFields(['password']);
            }
            callback()
        }
    }
    handlePasswordOk = () => {
        this.formRef.current.submit()
    }
    handlePasswordCancel = () => {
        this.formRef.current.resetFields();
        this.setState({
            isModalVisible: false
        })
    }
    handlePhoneOk = () => {
        this.setState({
            isPhoneModalVisible: true
        })
    }
    handlePhoneCancel = () => {
        this.formPhoneRef.current.resetFields()
        this.setState({
            isPhoneModalVisible: false,
            current: this.state.current === 2 ? 0 : this.state.current
        })
    }
    showPasswordModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    showPhoneModal = () => {
        this.setState({
            isPhoneModalVisible: true
        }, ()=> {
            this.formPhoneRef.current.setFieldsValue({telephone: setHidePhone(this.state.userInfo.user.Telephone)})
        })
    }
    nextStep = () => {
        this.formPhoneRef.current.submit()
    };
    stopInterval() {
        clearInterval(stopVal);
        this.setState({
            isSend: false,
            cutDownTime: 60,
        })
    }
    getPhoneCode = () => {
        let telephone = this.formPhoneRef.current.getFieldValue('telephone');
        if(this.state.current === 0) {
            telephone = this.state.userInfo.user.Telephone
        }
        let params = {
            telephone: telephone
        }
        if (!params.telephone) {
            message.error('请输入正确手机号');
            return;
        }
        this.setState({
            isSend: true,
        })
        // 发送验证码
        http.post('/user/sms/send', params).then(res => {
            stopVal = setInterval(() => {
                if (this.state.cutDownTime === 0) {
                    this.stopInterval();
                }
                this.setState({
                    cutDownTime: this.state.cutDownTime - 1
                })
            }, 1000)
        }).catch(() => {
            this.stopInterval();
        })
    }
    onFinish(values) {
        http.post('/user/change-password', values).then(res => {
            this.formRef.current.resetFields()
            this.handlePasswordCancel();
            message.success('修改成功');
        }, () => {
        })
    }
    onPhoneFinish(values) {
        let path = '/user/change-telephone';
        if(this.state.current === 0) {
            path = '/user/verify-telephone';
            values.telephone = this.state.userInfo.user.Telephone;
        }
        http.post(path, values).then(res => {
            this.formPhoneRef.current.resetFields()
            this.stopInterval();
            this.setState({
                current: this.state.current + 1
            }, ()=> {
                if (this.state.current === 2) {
                    getUserInfo((res)=> {
                        this.getUserInfo()
                    })
                }
            })
        }, () => {
        })
    }

    setMenuItem() {
        return (
            <Menu>
                <Menu.Item>
                    <p className={'menu-item-text'} onClick={this.showPhoneModal}>
                        修改手机号
                    </p>
                </Menu.Item>
                <Menu.Item>
                    <p className={'menu-item-text'} onClick={this.showPasswordModal}>
                        修改密码
                    </p>
                </Menu.Item>
            </Menu>
        )
    }

    setHeaderText() {
        return this.state.currentRoute.pathname === '/' ||
            this.state.currentRoute.pathname === '/retrieve-password' ||
            this.state.currentRoute.pathname === 'reset-password'
    }

    render() {
        let userInfo = sessionStorage.getItem('adminUserInfo') || '{}';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }
        const {isConfirmVisible, isModalVisible, confirmPasswordRules, passwordRules, isPhoneModalVisible, current, steps} = this.state;

        return (
            <div className={"author-header-container content-header"}>
                <div className="header-content">
                    {
                        this.setHeaderText() ?
                            <div className="img-box">
                                <span className="current-page">天下大足管理后台操作系统</span>
                            </div> :
                            <div className="img-box">
                                <span className="current-page"> {getCurrentDate()}，</span>
                                <span className="welcome-text">欢迎您使用天下大足管理后台操作系统</span>
                            </div>
                    }
                    {
                        userInfo.user ?
                            <div className="avatar-box">
                                <div className="user-name-box">
                                    <img src={userAvatar} className={'avatar-img'} alt={''}/>
                                    <Dropdown overlay={this.setMenuItem()} overlayClassName={'index-menu self-center-menu'}>
                                        <p className="ant-dropdown-link user-name">
                                            {userInfo.user.UserName}
                                            <DownOutlined/>
                                        </p>
                                    </Dropdown>
                                    {/*<p className="user-name">{userInfo.user.UserName}</p>*/}
                                </div>
                                <p className="logout-btn" onClick={this.showModal}>退出登录</p>
                            </div>
                            :
                            <div className="login-aside">
                                {
                                    this.state.currentRoute.pathname === '/retrieve-password' &&
                                        <Link className={'link-text'} to={'/'}>登录</Link>
                                }
                                <a href={process.env.REACT_APP_WEB_PATH}  className={'link-text not-line'} target="_blank" rel="noreferrer">平台首页</a>
                            </div>
                    }
                </div>
                <Modal
                    visible={isConfirmVisible}
                    cancelText={'取消'}
                    okText={'确定'}
                    centered
                    onOk={this.handleOk}
                    className="set-padding"
                    onCancel={this.handleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring"/>
                        确定退出登录吗?</p>
                </Modal>

                <Modal
                    visible={isModalVisible}
                    cancelText={'取消'}
                    okText={'保存'}
                    title={'修改密码'}
                    width={'640px'}
                    onOk={this.handlePasswordOk}
                    onCancel={this.handlePasswordCancel}
                    className="password-dialog form-content"
                >
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                    >
                        <Form.Item
                            label="当前密码"
                            name="old_password"
                            rules={password}
                            className={'input-password not-extra'}
                            validateFirst={true}
                        >
                            <Input.Password size="middle" placeholder={'请输入密码'}/>
                        </Form.Item>
                        <Form.Item
                            label="登录密码"
                            name="password"
                            rules={passwordRules}
                            extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                            className={'input-password'}
                            validateFirst={true}
                        >
                            <Input.Password size="middle" placeholder={'请输入密码'}/>
                        </Form.Item>
                        <Form.Item
                            label="确认密码"
                            name="confirm_password"
                            rules={confirmPasswordRules}
                            className="confirm-input not-extra input-password"
                            validateFirst={true}
                        >
                            <Input.Password size="middle" placeholder={'请再次输入密码'}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    visible={isPhoneModalVisible}
                    cancelText={''}
                    okText={''}
                    title={'修改手机号'}
                    width={'640px'}
                    onOk={this.handlePhoneOk}
                    onCancel={this.handlePhoneCancel}
                    className="phone-dialog password-dialog"
                >
                    <Form
                        name="basic"
                        ref={this.formPhoneRef}
                        onFinish={(values) => this.onPhoneFinish(values)}
                    >
                        <div className="step-title">
                            <Steps current={current}>
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title}/>
                                ))}
                            </Steps>
                            {
                                current === 0 &&
                                <div className={'steps-content'}>
                                    <Form.Item
                                        label="当前手机号"
                                        name="telephone"
                                        rules={not_null}
                                        className="not-extra input-phone"
                                    >
                                        <Input size="middle" disabled placeholder={'请输入手机号'}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="短信验证码"
                                        name="sms_code"
                                        rules={phone_code}
                                        className="not-extra news-code-input number-code"
                                    >
                                        <Input
                                            suffix={
                                                <div className="send-box">
                                                    {
                                                        this.state.isSend &&
                                                        <p className="send-code-btn number-btn">
                                                            {this.state.cutDownTime}
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.isSend &&
                                                        <p className="send-code-btn"
                                                           onClick={() => this.getPhoneCode()}>
                                                            发送验证码
                                                        </p>
                                                    }
                                                </div>
                                            }
                                            size="middle" placeholder={'请输入短信验证码'}/>
                                    </Form.Item>
                                </div>

                            }
                            {
                                current === 1 &&
                                <div className={'steps-content'}>
                                    <Form.Item
                                        label="新手机号"
                                        name="telephone"
                                        rules={phone}
                                        className="not-extra input-phone"
                                    >
                                        <Input size="middle" placeholder={'请输入手机号'}/>
                                    </Form.Item>
                                    <Form.Item
                                        label="短信验证码"
                                        name="sms_code"
                                        rules={phone_code}
                                        className="not-extra news-code-input number-code"
                                    >
                                        <Input
                                            suffix={
                                                <div className="send-box">
                                                    {
                                                        this.state.isSend &&
                                                        <p className="send-code-btn number-btn">
                                                            {this.state.cutDownTime}
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.isSend &&
                                                        <p className="send-code-btn"
                                                           onClick={() => this.getPhoneCode()}>
                                                            发送验证码
                                                        </p>
                                                    }
                                                </div>
                                            }
                                            size="middle" placeholder={'请输入短信验证码'}/>
                                    </Form.Item>
                                </div>
                            }
                            {
                                current === 2 &&
                                <div className={'steps-content'}>
                                    <div className="success-tip-box">
                                        <img src={imgSuccess} alt="" className="img-tip"/>
                                        <div className="text-box">
                                            <p className="title">手机号修改成功</p>
                                            <p className="desc">新的手机号码，可以找回密码，但不能用于登录</p>
                                            <p className="close-btn" onClick={this.handlePhoneCancel}>我知道了</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="steps-action">
                                {current === 0 && (
                                    <Button type="primary" onClick={() => this.nextStep()}>
                                        下一步
                                    </Button>
                                )}
                                {current === 1 && (
                                    <Button type="primary" className={'submit-replenish'} onClick={() => this.nextStep()}>
                                        确定
                                    </Button>
                                )}
                                {
                                    current !==2 &&
                                    <p className="close-btn" onClick={this.handlePhoneCancel}>取消</p>
                                }
                            </div>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Header)
