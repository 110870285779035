import React from 'react';
import {Button, Form, Input, message} from 'antd';
import './index.scss'
import http from './../../lib/service/http'
import Cookie from 'js-cookie';
import {rules} from "../../lib/help/rules";
import imgLogin from '../../resource/images/public/img-login.svg'

const {password, phone_code, phone} = rules;


const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}

export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
            cutDownTime: 60,
            loginInfo: {}
        }
    }

    onFinish(values) {
        http.post('/user/first-change-password', values).then(res => {
            message.success('激活成功，请重新登录');
            this.goLogin();
        }, () => {
            this.formRef.current.setFieldsValue({captcha: ''})
        })
    };

    handleCheckPwd = (_, value, callback) => {
        let cfmPwd = this.formRef.current.getFieldValue('confirm_password');
        if (cfmPwd && value && cfmPwd !== value) {
            callback(new Error('两次密码不一致'))

        } else {
            if (!this.formRef.current.isFieldValidating(['confirm_password']) && cfmPwd) {
                this.formRef.current.validateFields(['confirm_password']);
            }
            callback()
        }
    }

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if (!this.formRef.current.isFieldValidating(['password']) && loginPass) {
                this.formRef.current.validateFields(['password']);
            }
            callback()
        }
    }

    getPhoneCode = () => {
        let params = {
            telephone: this.formRef.current.getFieldValue('telephone'),
        }
        if (!params.telephone) {
            message.error('请输入正确手机号');
            return;
        }
        this.setState({
            isSend: true,
        })
        // 发送验证码
        let tmpVal
        http.post('/user/sms/send', params).then(res => {
            tmpVal = setInterval(() => {
                if (this.state.cutDownTime === 0) {
                    clearInterval(tmpVal);
                    this.setState({
                        isSend: false,
                        cutDownTime: 60,
                    })
                }
                this.setState({
                    cutDownTime: this.state.cutDownTime - 1
                })
            }, 1000)
        }).catch(() => {
            clearInterval(tmpVal);
            this.setState({
                isSend: false,
                cutDownTime: 60,
            })
        })
    }

    goLogin() {
        Cookie.remove('token');
        sessionStorage.clear();
        this.props.history.replace('/')
    }

    onFinishFailed(errorInfo) {
    };

    componentDidMount() {
        let tmpInfo = sessionStorage.getItem('loginInfo') || '{}'
        tmpInfo = JSON.parse(tmpInfo);
        if(!tmpInfo.role){
            this.goLogin();
            return
        }
        this.setState({
            loginInfo: tmpInfo
        })
        if (tmpInfo.role === 'ADMIN') {
            this.formRef.current.setFieldsValue({telephone: tmpInfo.telephone})
        }
    }

    componentWillUnmount() {
    }


    render() {
        const {confirmPasswordRules, passwordRules, loginInfo} = this.state
        return (
            <div className="login-container reset-password-container">
                <div className="login-content">
                    <div className="login-left">
                        <p className="title">欢迎来到</p>
                        <p className="desc">天下大足管理后台操作系统</p>
                        <img src={imgLogin} alt="" className="img-login"/>
                    </div>
                    <div className="login-right">
                        <p className="login-title">
                            Hi，{loginInfo.username}
                        </p>
                        <p className="left-desc">
                            首次登录，请按照以下步骤激活您的管理员账号
                        </p>
                        <Form
                            {...layout}
                            name="basic"
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}
                        >
                            <Form.Item
                                label=""
                                name="telephone"
                                rules={phone}
                                extra={'该号码用于重置密码，请妥善保管'}
                                className="input-phone"
                            >
                                <Input size="large" disabled={loginInfo.role === 'ADMIN'} placeholder={'请输入手机号'}/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="sms_code"
                                rules={phone_code}
                                className="not-extra news-code-input number-code"
                            >
                                <Input
                                    suffix={
                                        <div className="send-box">
                                            {
                                                this.state.isSend &&
                                                <p className="send-code-btn number-btn">
                                                    {this.state.cutDownTime}
                                                </p>
                                            }
                                            {
                                                !this.state.isSend &&
                                                <p className="send-code-btn"
                                                   onClick={() => this.getPhoneCode()}>
                                                    获取验证码
                                                </p>
                                            }
                                        </div>
                                    }
                                    size="large" placeholder={'请输入短信验证码'}/>
                            </Form.Item>
                            <Form.Item
                                label=""
                                name="password"
                                rules={passwordRules}
                                extra={'8-16位字符，含数字、大小写字母、特殊字符中的三种及以上'}
                                className={'input-password'}
                                validateFirst={true}
                            >
                                <Input.Password size="large" placeholder={'请输入密码'}/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="confirm_password"
                                rules={confirmPasswordRules}
                                className="confirm-input not-extra input-password"
                                validateFirst={true}
                            >
                                <Input.Password size="large" placeholder={'请再次输入密码'}/>
                            </Form.Item>

                            <Form.Item {...tailLayout} className="submit-btn-box">
                                <Button className={'submit-btn last-step'} onClick={() => this.goLogin()}>
                                    上一步
                                </Button>
                                <Button className={'submit-btn'} type="primary" htmlType="submit">
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
