import React from 'react';
import {Row, Col, Modal, Form, Input, Button, message} from "antd";
import http from "../../lib/service/http";
import {getImgUrl, localDate} from "../../lib/help/helper";
import {rules} from '../../lib/help/rules'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import jigouLogo from "../../resource/images/public/jigou_logo.svg"
const {not_null} = rules;
const {TextArea} = Input;
export default class Index extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      status: '',
      detailList: {},
      visible: false,
      isModalVisible: false,
      btnState: '',
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  pass = () => {
    let params = {
      product_id: Number(this.props.props.match.params.id),
      product_type: 'TRADING',
      audit_status: 'PUBLISHED',
    };
    const that = this;
    http.post('/product/audit', params).then(res => {
      message.success({
        content: '审核成功',
      });
      that.props.props.history.push('/product/financing-list');
    }, () => {

    })
  };
  reject = () => {
    this.setState({
      visible: true,
    });
  };

  getListData() {
    http.get('/product/financial-detail/' + this.props.props.match.params.id).then(res => {
      this.setState({
        detailList: res.detail,
        status: res.detail.Status
      });
    })
  };

  componentDidMount() {
    this.getListData();
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      isModalVisible: false,
    });
  };
  onFinish = (values) => {
    const that = this;
    let params = {
      product_id: Number(this.props.props.match.params.id),
      product_type: 'TRADING',
      audit_status: 'PUBLISH-REJECTED',
      reject_reason: values.reject_reason,
    };
    http.post('/product/audit', params).then(res => {
      message.success({
        content: '审核成功',
      });
      that.props.props.history.push('/product/financing-list');
    }, () => {
    });
    this.setState({
      visible: false,
    });
  };

  onFinishFailed(errorInfo) {
  };
  editProjectConfirm = () => {
    let params = {
      product_id: this.state.detailList.ID,
      product_type: 'TRADING',
    }
    http.post(`/product/shelve`, params).then(res => {
      if (res) {
        message.success('操作成功');
        this.props.props.history.replace('/product/financing-list')
      }
    })
  }
  showShelveModal(type) {
    this.setState({
      btnState: type,
      isModalVisible: true,
    })
  }

  render() {
    const {detailList, visible, isModalVisible, btnState} = this.state;
    return (
      <div className="admin-user-container debt-finance main-container">
        <div className=" debt-content debt-detail admin-user-content">
          <div className={'debt-detail-content'}>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>产品名称</Col>
              <Col span={21} className={'name-text'}>{detailList.Name}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>机构logo</Col>
              <Col span={21} className={'name-text'}>
                {
                  detailList.LogoFid ?
                    <div className="logo-box">
                      <img src={getImgUrl(detailList.LogoFid)} alt=""/>
                    </div> : <img src={jigouLogo} alt=""/>
                }
              </Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>贷款额度范围</Col>
              <Col span={21}
                   className={'name-text'}>{detailList.AmountCeil ? detailList.AmountFloor + "-" + detailList.AmountCeil + "万元" : detailList.AmountFloor + "万元以上"}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>贷款利率范围</Col>
              <Col span={21}
                   className={'name-text'}>{detailList.RateCeil ? detailList.RateFloor + "%-" + detailList.RateCeil + "%" : detailList.RateFloor + "%以上"}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>担保方式</Col>
              <Col span={21} className={'name-text'}>{detailList.Secured}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>期限范围</Col>
              <Col span={21}
                   className={'name-text'}> {detailList.CycleCeil ? detailList.CycleFloor + "-" + detailList.CycleCeil + "个月" : detailList.CycleFloor + "个月以上"}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>有效期</Col>
              <Col span={21}
                   className={'name-text'}>{detailList.Validity === 'FIXED' ? (localDate(detailList.ValidityStart) + ' -- ' + localDate(detailList.ValidityEnd)) : '长期有效'}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>联系方式</Col>
              <Col span={21} className={'name-text'}>{detailList.Contact}</Col>
            </Row>
            <Row className={'debt-row debt-line'}>
              <Col span={3} className={'name-title'}>适用地区</Col>
              <Col span={21} className={'name-text'}>{detailList.Area}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>产品特点</Col>
              <Col span={21} className={'name-text name-text-other'}>
                <div dangerouslySetInnerHTML={{__html: detailList.Description}}/>
              </Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>申请条件</Col>
              <Col span={21} className={'name-text name-text-other'}>{detailList.Requirement}</Col>
            </Row>
            <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>所需材料</Col>
              <Col span={21} className={'name-text name-text-other'}>{detailList.Data}</Col>
            </Row>
            {detailList.Remark && detailList.Remark.trim().length > 0 && <Row className={'debt-row'}>
              <Col span={3} className={'name-title'}>其他说明</Col>
              <Col span={21} className={'name-text name-text-other'}>{detailList.Remark}</Col>
            </Row>}
            <div className={'debt-row'}>
              {
                this.state.status === 'REVIEW-PASS' &&
                <Row>
                  <Col span={3} className={'name-title'}/>
                  <Col span={21} className={'name-text'}>
                    <div className={'btn-operate'}>
                      <span className="btn btn-pass" onClick={() => this.pass()}>通过</span>
                      <span className="btn btn-reject" onClick={() => this.reject()}>驳回</span>
                    </div>
                  </Col>
                </Row>
              }
              {
                this.state.status === 'PUBLISHED' &&
                <Row>
                  <Col span={3} className={'name-title'}/>
                  <Col span={21} className={'name-text'}>
                    <div className={'btn-operate'}>
                      <span className="btn btn-pass" onClick={() => this.showShelveModal('off')}>下架</span>
                    </div>
                  </Col>
                </Row>
              }
              {
                this.state.status === 'SHELVED' &&
                <Row>
                  <Col span={3} className={'name-title'}/>
                  <Col span={21} className={'name-text'}>
                    <div className={'btn-operate'}>
                      <span className="btn btn-pass" onClick={() => this.showShelveModal('on')}>恢复</span>
                    </div>
                  </Col>
                </Row>
              }

              {/*{this.state.status === 'REVIEW-PASS' ? (*/}
              {/*    <Row className={'debt-row debt-other-line'}>*/}
              {/*        <Col span={3} className={'name-title'}/>*/}
              {/*        <Col span={21} className={'name-text'}>*/}
              {/*            <div className={'btn-operate'}>*/}
              {/*                <span className="btn btn-pass" onClick={() => this.pass()}>通过</span>*/}
              {/*                <span className="btn btn-reject" onClick={() => this.reject()}>驳回</span>*/}
              {/*            </div>*/}
              {/*        </Col>*/}
              {/*    </Row>*/}
              {/*) : (this.state.status === 'REVIEW-REJECTED' ? (*/}
              {/*    <div>*/}
              {/*        <Row className={'debt-row debt-other-line'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*            <Col span={21} className={'name-text name-text-other'}>机构驳回</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>驳回原因</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{detailList.RejectReason}</Col>*/}
              {/*        </Row>*/}
              {/*    </div>*/}
              {/*) : (this.state.status === 'PUBLISHED' ? (*/}
              {/*    <div>*/}
              {/*        <Row className={'debt-row debt-other-line'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*            <Col span={21} className={'name-text name-text-other'}>已发布</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>发布时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*    </div>*/}
              {/*) : (this.state.status === 'PUBLISH-REJECTED' ? (*/}
              {/*    <div>*/}
              {/*        <Row className={'debt-row debt-other-line'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*            <Col span={21} className={'name-text name-text-other'}>发布驳回</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row '}>*/}
              {/*            <Col span={3} className={'name-title'}>发布驳回时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row '}>*/}
              {/*            <Col span={3} className={'name-title'}>发布驳回原因</Col>*/}
              {/*            /!*未展示*!/*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{detailList.AdminRejectReason && detailList.AdminRejectReason.length > 0 ? detailList.AdminRejectReason.length : '--'}</Col>*/}
              {/*        </Row>*/}
              {/*    </div>*/}
              {/*) : (this.state.status === 'SHELVED' ? <div>*/}
              {/*    <Row className={'debt-row debt-other-line'}>*/}
              {/*        <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*        <Col span={21} className={'name-text name-text-other'}>已下架</Col>*/}
              {/*    </Row>*/}
              {/*    <Row className={'debt-row'}>*/}
              {/*        <Col span={3} className={'name-title'}>下架时间</Col>*/}
              {/*        <Col span={21}*/}
              {/*             className={'name-text name-text-other'}>{localTime(detailList.AdminAuditedAt)}</Col>*/}
              {/*    </Row>*/}
              {/*</div> : (this.state.status === 'PENDING-REVIEW' ?*/}
              {/*    <div>*/}
              {/*        <Row className={'debt-row debt-other-line'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*            <Col span={21} className={'name-text name-text-other'}>待机构审核</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*    </div>:*/}
              {/*    <div>*/}
              {/*        <Row className={'debt-row debt-other-line'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核状态</Col>*/}
              {/*            <Col span={21} className={'name-text name-text-other'}>审核通过</Col>*/}
              {/*        </Row>*/}
              {/*        <Row className={'debt-row'}>*/}
              {/*            <Col span={3} className={'name-title'}>审核时间</Col>*/}
              {/*            <Col span={21}*/}
              {/*                 className={'name-text name-text-other'}>{localTime(detailList.AuditedAt)}</Col>*/}
              {/*        </Row>*/}
              {/*    </div>)))))}*/}
            </div>
          </div>
        </div>
        <Modal
          visible={isModalVisible}
          cancelText={'取消'}
          okText={'确定'}
          centered
          onOk={this.editProjectConfirm}
          className="set-padding"
          onCancel={this.handleCancel}>
          <p className='account-modal-title logout-title'>
            <ExclamationCircleOutlined className="icon-waring"/>
            确定{btnState === 'off' ? '下架' : '恢复'}该产品吗?</p>
          <p className="account-modal-desc">
            {btnState === 'off' ? '下架后的产品将不再显示在前台网站中' : '恢复后的产品将重新显示在前台网站中'}
          </p>
        </Modal>
        <Modal title={'审核产品'}
               visible={visible}
               onCancel={this.handleCancel}
               onOk={this.handleCancel}
               okText={'确定'}
               footer={null}
               width={640}
               wrapClassName='modal-reject'
               destroyOnClose={true}
        >
          <Form
            name="basic"
            ref={this.formRef}
            onFinish={(values) => this.onFinish(values)}
            onFinishFailed={() => this.onFinishFailed()}>
            <Form.Item
              label="驳回原因"
              name="reject_reason"
              rules={not_null}
              validateFirst={true}
            >
              <TextArea size="middle" placeholder={'请输入驳回原因'}/>
            </Form.Item>
            <Form.Item className="footer-btn">
              <Button className={'submit-btn cancel-btn'} type="primary" onClick={this.handleCancel}>
                取消
              </Button>
              <Button className={'submit-btn'} type="primary" htmlType="submit">
                确定
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
