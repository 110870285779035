import axios from 'axios';
import Cookie from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';
import { message, Spin } from 'antd';
import requestSign from "zigg-request-sign"
message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
    rtl: true,
});
const BASE_URL = process.env.REACT_APP_ADMIN_API+'/v1';
// const BASE_URL = 'http://192.168.2.36:9999/v1';

let defaultConfig = {
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        // 'Authorization': '',
        // 'locale': ''
    }
};
let instance = axios.create(defaultConfig);

let requestCount = 0;

function showLoading() {
    if(requestCount === 0) {
        var dom = document.createElement('div');
        dom.setAttribute('id', 'loading');
        document.body.appendChild(dom);
        ReactDOM.render(<Spin tip=""  size="large"/>, dom)
    }
    requestCount++;
}
function hideLoading () {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}
function handleError(error, vc) {
    //是否传递了vue component
    if (vc) {
        vc.loading = false;
    }
    if (axios.isCancel(error)) { // 如果是用户主动取消的
        return;
    }
    if (error.response) { // 服务器错误
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error.response.status === 401) {
            if(error.response.data && error.response.data.message) {
                message.error({
                    content: error.response.data.message,
                });
            }else {
                message.error({
                    content: '您的登录状态已过期,请重新登录',
                });
            }
            Cookie.remove('token');
            sessionStorage.clear();
            setTimeout(() => window.location.href = "/#/", 2500);
            return;
        }
        message.error({
            content: error.response.data.message || '服务端错误',
        });
        if(Number(error.response.data.err_code) === 1001) {
            setTimeout(() => window.location.href = "/#/", 2500);
            Cookie.remove('token');
            sessionStorage.clear();
        }
    } else if (error.request) {
        message.error({
            content: '请求失败',
        });
    } else {
        message.error({
            content: '请求失败',
        });
    }
}

const http = {
    getBaseURL: () => {
        return defaultConfig.baseURL;
    },
    request: (params, config) => {
        if (!params || Object.prototype.toString.call(params) !== "[object Object]") {
            throw new Error("params is undefined or not an object")
        }
        //设置私有接口Authorization
        if (params.authApi) {
            let token = Cookie.get('token');
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
            delete instance.defaults.headers.common['Authorization'];
        }

        return new Promise((resolve, reject) => {
            instance.request(params).then(res => {
                if (!config || config.isLoading !== false) {
                    hideLoading()
                }
                if (res.status === 200) {
                    resolve(res.data);
                }else if(res.status === 201){
                    message.warning({
                        content: '首次登录，请修改密码',
                    });
                    setTimeout(() => window.location.href = "/#/reset-password", 2500);
                } else {
                    handleError(res, params.vc);
                    reject(res);
                }
            }).catch(error => {
                if (!config || config.isLoading !== false) {
                    hideLoading()
                }
                handleError(error, params.vc);
                reject(error);
            });
        });
    },
    getConfig: (method, url, data, config) => {
        let params = {
            url: url,
            method: method
        };
        if (method === 'get') {
            data && (params.params = data);
        } else {
            data && (params.data = data);
        }
        //没有传递authApi参数都是私有接口
        if (!config) {
            config = {};
            config.authApi = true;
        }
        if (config && !config.hasOwnProperty('authApi')) config.authApi = true;
        config && Object.assign(params, config);
        return params;
    },
    get: (url, data, config) => {
        if (!config || config.isLoading !== false) {
            showLoading()
        }
        let params = http.getConfig('get', url, requestSign(process.env.REACT_APP_SECRET, url, data), config);
        return http.request(params, config);
    },
    post: (url, data, config) => {
        if (!config || config.isLoading !== false) {
                showLoading()
        }
        let params = http.getConfig('post', url, requestSign(process.env.REACT_APP_SECRET, url, data), config);
        return http.request(params, config);
    }
};

export default http;
