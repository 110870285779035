import React from 'react';
import {Form, Input, Button, DatePicker, Row, Col, Pagination, Table} from 'antd';
import './index.scss'
import http from "../../lib/service/http";
import locale from 'antd/es/date-picker/locale/zh_CN';
import NoData from "../../components/NoData";
import {localTime} from "../../lib/help/helper";
import {Link} from 'react-router-dom'


const {RangePicker} = DatePicker;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 24},
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}


export default class UserCompanyList extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            columns: [
                {
                    title: 'UID',
                    dataIndex: 'ID',
                    key: 'ID',
                },
                {
                    title: '企业名称',
                    dataIndex: 'Name',
                    key: 'Name',
                    render: (Name)=> {
                        return Name ? Name : '- -'
                    }
                },
                {
                    title: '信用代码',
                    dataIndex: 'UnifiedSocialCreditCode',
                    key: 'UnifiedSocialCreditCode',
                    render: (UnifiedSocialCreditCode)=> {
                        return UnifiedSocialCreditCode ? UnifiedSocialCreditCode : '- -'
                    }
                },
                {
                    title: '法人姓名',
                    dataIndex: 'LegalPersonName',
                    key: 'LegalPersonName',
                    render: (LegalPersonName)=> {
                        return LegalPersonName ? LegalPersonName : '- -'
                    }
                },
                {
                    title: '手机号',
                    dataIndex: 'Telephone',
                    key: 'Telephone',
                },
                {
                    title: '注册时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    render: (time) => {
                        return localTime(time)
                    }
                },
                {
                    title: '企业账号',
                    dataIndex: 'UserName',
                    key: 'UserName',
                    render: (UserName)=> {
                        return UserName ? UserName : '- -'
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'Disabled',
                    key: 'Disabled',
                    render: (Disabled, rows) => (
                        <div>
                            <Link to={'/user/company-list/'+rows.ID}>查看详情</Link>
                        </div>

                    ),
                },
            ],
            total: 0,
            currentPage: 1,
            visible: false,
            isModalVisible: false,
            modalType: 'enable',
            userId: '',
            searchInfo: {
                reg_start_date: '',
                reg_end_date: '',
                legal_person_name: '',
                username: '',
                usci: '',
                ent_name: '',
            },
        }
    }

    componentDidMount() {
        this.getListData()
    }

    componentWillUnmount() {
    }

    getListData = () => {
        let params = this.state.searchInfo;
        params.page = this.state.currentPage;
        http.get('/front-user/enterprise-list', params).then(res => {
            this.setState({
                dataList: res.data.items ? res.data.items : [],
                total: res.data.total,
                page: res.data.total_page,
                currentPage: res.data.current_page
            });
        })
    }
    onChangePage = (page) => {
        this.setState({
            currentPage: page,
        }, () => {
            this.getListData()
        });
    }

    onFinish(value) {
        if (value.range_picker) {
            value.reg_start_date = value.range_picker[0].format('YYYY-MM-DD')+" 00:00:00";
            value.reg_end_date = value.range_picker[1].format('YYYY-MM-DD')+" 23:59:59";
            delete value.range_picker;
        }
        this.setState({
            currentPage: 1,
            searchInfo: {
                ...value
            }
        }, ()=> {
            this.getListData();
        })

    }

    onFinishFailed(value) {
    }

    confirmHandleCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }
    resetSearch = () => {
        this.formRef.current.resetFields();
        this.formRef.current.submit();
    }

    render() {
        const {columns, dataList, total, currentPage} = this.state;
        return (
            <div className="admin-user-container main-container user-company-container">
                <div className="admin-user-content">
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        className="form-content search-form-content"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Row gutter={24}>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="企业名称"
                                    name="ent_name"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="信用代码"
                                    name="usci"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="企业账号"
                                    name="username"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={8} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    label="法人姓名"
                                    name="legal_person_name"
                                    className={'search-item'}
                                >
                                    <Input placeholder={'可按关键字模糊搜索'}/>
                                </Form.Item>
                            </Col>
                            <Col xl={9} lg={12}>
                                <Form.Item
                                    {...formItemLayout}
                                    name="range_picker"
                                    label="注册时间"
                                    className={'not-extra date-time search-item'}
                                >
                                    <RangePicker locale={locale} format="YYYY-MM-DD"/>
                                </Form.Item>
                            </Col>
                            <Col xl={7} lg={12} className="edit-btn-box">
                                <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                                    <Button className={'submit-btn'} type="primary" htmlType="submit">
                                        搜索
                                    </Button>
                                </Form.Item>
                                <Form.Item {...tailLayout} className="reset-btn btn-submit">
                                    <Button className={'edit-btn'} onClick={()=>this.resetSearch()}>
                                        重置
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>


                    </Form>
                    {
                        dataList.length > 0 ?
                            <div className="list-box">
                                <Table rowKey={'ID'} columns={columns} dataSource={dataList} pagination={false}/>
                                <Pagination
                                    className={'page-style'}
                                    onChange={this.onChangePage}
                                    defaultCurrent={1}
                                    total={total}
                                    pageSize={10}
                                    current={currentPage}
                                    showSizeChanger={false}
                                    showTotal={total => `共${total}条`}
                                />
                            </div> :
                            <div className="list-box">
                                <NoData/>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
