import React from 'react';
import {Table, Space, Pagination, Row, Col, Form, Input, Button} from "antd";
import {withRouter} from "react-router-dom";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";
import NoData from "../../components/NoData";


class ProjectFinancingList extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleRole: false,
      data: [],
      currentPage: 1,
      columns: [
        {
          title: '订单编号',
          dataIndex: 'ID',
        },
        {
          title: '产品名称',
          dataIndex: 'ProductName',
        },
        {
          title: '类型',
          dataIndex: 'ProductTypeStr',
        },
        {
          title: '发布机构',
          dataIndex: 'FinName',
        },
        {
          title: '申请企业',
          dataIndex: 'EntName',
        },
        {
          title: '交易状态',
          render: (record) => {
            return (record.OrderStatus==='撮合成功'?<div>
              <p>{record.OrderStatus}</p>
              <p style={{color: '#EB0000'}}>交易额:{record.Amount}万元</p>
            </div>:record.OrderStatus)
          }
        },
        {
          title: '申请时间',
          dataIndex: 'CreatedAt',
          render: (CreatedAt) => {
            return (localTime(CreatedAt))
          }
        },
        {
          title: '操作',
          render: (text, record) => (
            <Space size="middle" className={'operate-btn'}>
              <p onClick={this.goDetails.bind(this, record)}>查看详情</p>
            </Space>
          ),
        },
      ],
      listType: ['','MATCH-SUCCESS', 'PENDING-DISTRIBUTE', 'PENDING-MATCH', 'MATCH-FAILED', 'CLOSED', 'EXPIRED'],
      searchInfo: {
        name: '',
        fin_name: '',
        status: '',
      },
      editRowsId: ''
    }
  }

  goDetails = (record) => {
    this.props.history.push({
      pathname: '/order/company-list/' + record.ID,
      state: '产品详情'
    });
  };
  getList = () => {
    let params = this.state.searchInfo;
    params.order_status = this.state.listType[this.props.activeKey];
    params.page = this.state.currentPage;
    params.product_type='SERVICE';
    http.get(`/product-order/list`, params).then(res => {
      if (res) {
        this.setState({
          data: res.data.items ? res.data.items : [],
          total: res.data.total,
          page: res.data.total_page,
          currentPage: res.data.current_page
        })
      }
    })
  };
  componentDidMount() {
    this.getList()
  }

  componentDidUpdate(prevProps, prevState) {
    const key = this.props.activeKey;
    let params = {
      page: this.state.currentPage
    };
    if (this.props.activeKey === '1' && (prevProps.activeKey !== this.props.activeKey)) {
      this.getList(key, params);
      this.setState({
        currentPage: 1
      })
    }
  }

  onFinish(value) {
    this.setState({
      currentPage: 1,
      searchInfo: {
        ...value
      }
    }, () => {
      this.getList();
    })
  }

  onChangePage = page => {
    this.setState({
      currentPage: page,
    }, () => {
      this.getList()
    });
  };
  resetSearch = () => {
    this.formRef.current.resetFields();
    this.formRef.current.submit();
  }
  render() {
    const {data, total} = this.state;
    return (
      <div className="user-manage">
        <Form
          name="basic"
          className="form-content search-form-content"
          ref={this.formRef}
          onFinish={(values) => this.onFinish(values)}
          onFinishFailed={() => this.onFinishFailed()}
        >
          <Row gutter={24}>
            <Col xl={6} lg={12}>
              <Form.Item
                label="产品名称"
                name="product_name"
                className={'search-item'}
              >
                <Input placeholder={'可按关键字模糊搜索'}/>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12}>
              <Form.Item
                label="发布机构"
                name="fin_name"
                className={'search-item'}
              >
                <Input placeholder={'可按关键字模糊搜索'}/>
              </Form.Item>
            </Col>
            <Col xl={6} lg={12}>
              <Form.Item
                label="申请企业"
                name="ent_name"
                className={'search-item'}
              >
                <Input placeholder={'可按关键字模糊搜索'}/>
              </Form.Item>
            </Col>
            <Col xl={4} lg={12} className="edit-btn-box">
              <Form.Item wrapperCol={{offset: 0, span: 8}} className="search-btn submit-btn-box">
                <Button className={'submit-btn'} type="primary" htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
              <Form.Item className="reset-btn btn-submit">
                <Button className={'edit-btn'} onClick={() => this.resetSearch()}>
                  重置
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="user-content">
          {total > 0 ? (<div className={'table-data'}>
            <Table rowKey={'ID'} columns={this.state.columns} dataSource={data} pagination={false}/>
            <div className={'total-data'}>
              <Pagination
                className={'page-style'}
                onChange={this.onChangePage.bind(this)}
                defaultCurrent={1}
                total={total}
                pageSize={10}
                showSizeChanger={false}
                current={this.state.currentPage}
                showTotal={total => `共${total}条`}
              />
            </div>
          </div>) : <div className="news-list-content">
            <NoData/>
          </div>}
        </div>
      </div>
    )
  }
}

export default withRouter(ProjectFinancingList)
